import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { UserSettingsDashboard } from './user_settings/user_settings_dashboard/user_settings_dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { ManagePermissionComponent } from './user_settings/user_settings_dashboard/manage-permissions/manage-permissions.component';
import { CustomerProfileGuardService } from './shared/guard/customer-profile.guard';
import { ManageGroupPermissionComponent } from './user_settings/user_settings_dashboard/manage-group-permissions/manage-group-permissions.component';


const routes: Routes = [
  { path: 'fullfillment', redirectTo: 'fullfillment/provision-view', pathMatch: 'full' },
  {
    path: '',
    component: ContentComponent,
    children: [
      { path: "", redirectTo: 'dashboard', pathMatch: 'full' },

      {
        path: "dashboard",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "search-result-page",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./search-result-page/search-result-page-routing.module").then((m) => m.SearchResultPageRoutingModule),
      },
      {
        path: 'review',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./review/review.module").then((m) => m.ReviewModule),
      },
      {
        path: 'fullfillment',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./fullfillment/fullfillment.module").then((m) => m.FullfillmentModule),
      },
      {
        path: 'awaiting-shipment',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./awaiting-shipment/awaiting-shipment.module").then((m) => m.AwaitingShipmentModule),
      },
      {
        path: 'porting',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./portIn-out/port.module").then((m) => m.PortModule),
      },
      { path: 'user-settings', component: UserSettingsDashboard, canActivate: [AuthGuardService], },
      {
        path: "manage-permissions",
        component: ManagePermissionComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: "manage-group-permissions",
        component:ManageGroupPermissionComponent,
        canActivate: [AuthGuardService],
      },


      //{ 
      //  path: 'review', 
      //  canActivate: [AuthGuardService],
      //  component: OrderDashboardComponent 
      // },

      {
        path: "customer-dashboard",
        canActivate: [AuthGuardService, CustomerProfileGuardService],
        loadChildren: () => import("./customer-dashboard/customer-dashboard.module").then((m) => m.CustomerDashboardModule),
      },

      {
        path: "ticket-system",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./ticket-system/ticket-system.module").then((m) => m.TicketSystemModule),
      },

      {
        path: "reports",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule),
      },

      {
        path: "subscriptions",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./subscriptions/subscriptions.module").then((m) => m.SubscriptionsModule),
      },
      {
        path: "scheduled-downgrades",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./scheduled-downgrades/scheduled-downgrades.module").then((m) => m.ScheduledDowngradeModule),
      },
      {
        path: "add-member-group",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./add-member-group/add-member-group.module").then((m) => m.AddMemberGroupModule),
      },

      {
        path: "profile",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule),
      },

      {
        path: "bulk-report",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./bulk-reports/bulk-reports.module").then((m) => m.BulkReportsModule),
      },

      {
        path: "bulk-process",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./bulk-users/bulk-users.module").then((m) => m.BulkUsersModule),
      },

      {
        path: "recent-customer-searches",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./recent-customer-searches/recent-customer-searches.module").then((m) => m.RecentCustomerSearchesModule),
      },

      {
        path: 'auth/login',
        component: LoginComponent
      },

    ]
  },
  {
    path: '**',
    redirectTo: '/review'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'disabled',
    scrollPositionRestoration: 'disabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
