import { Component, ViewEncapsulation, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit{

  public iconSidebar;
  public menuItems: any[];
  selectedCompanyLogo:any
  // public menuItems: Menu[];

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService, private cdr: ChangeDetectorRef) {}
  ngOnInit(){
    this.getLogoSrc();
    this.navServices.getSideMenuBar().subscribe((menuItems:any) => {
      this.menuItems = menuItems;
   
       this.cdr.detectChanges();
      this.getLogoSrc();
      this.menuItems= this.menuItems.filter(menuItem => !menuItem.isHidden);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // menuItems.filter(items => {
          //   if (items.path === event.url) {
          //     this.setNavActive(items);
          //   }
          //   if (!items.children) { return false; }
          //   items.children.filter(subItems => {
          //     if (subItems.path === event.url) {
          //       this.setNavActive(subItems);
          //     }
          //     if (!subItems.children) { return false; }
          //     subItems.children.filter(subSubItems => {
          //       if (subSubItems.path === event.url) {
          //         this.setNavActive(subSubItems);
          //       }
          //     });
          //   });
          // });
        }
      });
    });

  }
  

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  getLogoSrc(){
    const BaseUrl = localStorage.getItem('BaseUrl');
    const MINGLE_URL = environment.Prepaid_Mingle_API;  
    BaseUrl === MINGLE_URL ?  this.selectedCompanyLogo = 'Mingle' : 'Aqsatel';
  }

  // Active Nave state
  setNavActive(item) {    
    // Deactivate all menu items
  this.menuItems.forEach(menuItem => {
    menuItem.active = false;
    if (menuItem.children) {
      menuItem.children.forEach(submenuItem => {
        submenuItem.active = false;
      });
    }
  });

  // Activate the clicked menu item and its direct parent items
  const activateMenuItem = (menuItem) => {
    if (menuItem) {
      menuItem.active = true;
      if (menuItem.parent) {
        menuItem.parent.active = true;
        activateMenuItem(menuItem.parent);
      }
    }
  };

  activateMenuItem(item);
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      // this.menuItems.forEach(a => {
      //   if (this.menuItems.includes(item)) {
      //     a.active = false;
      //   }
      //   if (!a.children) { return false; }
      //   a.children.forEach(b => {
      //     if (a.children.includes(item)) {
      //       b.active = false;
      //     }
      //   });
      // });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
}
