import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { SettingCommonServices } from '../../services/setting-common.services';
import { Router } from '@angular/router';
import { ManageComboItemsService } from '../../services/manage-combo-items.service';
import { ManageComboService } from '../../services/manage-combo.service';
import { ManageMakeModelsService } from '../../services/manage-make-models.service';


@Component({
  selector: 'app-manage-make-models',
  templateUrl: './manage-make-models.component.html',
  styleUrls: ['./manage-make-models.component.scss']
})
export class ManageMakeModelsComponent {
  addMakeModelForm: any = FormGroup;
  @Input() manageMakeModelsDetails:any
  filterList: any = []
  public pageDropdownMenu: any = [10, 20, 30];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  showErrorMsg: boolean = false;
  deviceMakeModelList: any = [];
  device_modal_id: any = null;
  constructor(
    private fb: FormBuilder,
    public commonSettingService: SettingCommonServices,
    private router: Router,
    private manageComboItemService: ManageComboItemsService,
    public manageMakeModelService: ManageMakeModelsService,
    public commonService: CommonService
  ) {

    this.addMakeModelForm = this.fb.group({
      Make: [null, Validators.required],
      Model: [null, Validators.required],
      ModelNumber: [null, Validators.required],
      NetworkType: ['GSM', Validators.required],
      SimType: [null, Validators.required],
      WiFi: [false, Validators.required],
      Data: [false, Validators.required],
      OS: [null, Validators.required],
      VoiceOnly: [false, Validators.required],
      HotspotCapable: [false, Validators.required],
      EquipmentPhoneType: [null],
      DeviceType: [null, Validators.required],
      Esim: [false],
      Price: [null, Validators.required],
      PriceAfterDiscount: [null, Validators.required],
      Color: [null, Validators.required],
      Weight: [0, Validators.required],
      Unit: [null, Validators.required],

    })
  }

  ngOnInit(): void {

    this.commonSettingService.moreMakeModelTabLevel1.subscribe(
      data => {
        if (data == 'MANAGE_MAKE_MODELS') {
          this.getDeviceMakeModel()
        }
      }
    )

  }



  getDeviceMakeModel() {
    
    let data = {
      "per_page": this.itemsPerPage,
      "page": this.currentPage,
    }
    this.manageMakeModelService.getDeviceMakeModelList(data).subscribe((res: any) => {
      this.deviceMakeModelList = res.data.data;
      this.filterList = res.data.data
      this.totalItems = res.data.total
      this.currentPage = res.data.current_page
      this.first_page_url = res.data.first_page_url
      this.last_page_url = res.data.last_page_url
      this.next_page_url = res.data.next_page_url
      
    }, err => {
      
    })
  }
  onChangeNumberOfPage() {
    this.getDeviceMakeModel()
  }
  pageChange(event) {
    this.currentPage = event
    this.getDeviceMakeModel()
  }
  edit(item: any) {
    this.commonSettingService.moreMakeModelSelectedTab1('Line Information');
    this.commonSettingService.editGroup(item.id);
    let data = {
      "DeviceMakeModelID": item.id
    }
    this.manageMakeModelService.getDeviceMakeModelList(data).subscribe((res: any) => {
      this.addMakeModelForm.patchValue(res.data.data[0])
      this.device_modal_id = res?.data.data[0].id;
    })
  }
  submit() {
    this.addMakeModelForm.get('PriceAfterDiscount').patchValue(this.addMakeModelForm.value.Price)
    if (this.addMakeModelForm.valid) {
      
      this.addMakeModelForm.patchValue({EquipmentPhoneType:this.addMakeModelForm.value.DeviceType})
      this.manageMakeModelService.addDeviceMakeModel(this.addMakeModelForm.value).subscribe(res => {
        this.getDeviceMakeModel()
        this.resetForm()
        this.showErrorMsg = false;
        this.commonSettingService.toggleMoreMakeModelLevel1()
        this.commonService.showToast("success", "Success", "Added successfully!")
        
      }, err => {
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        
        this.showErrorMsg = true;
        this.showErrorMsg = false;
      })
    }
    else {
      this.commonService.showToast('error', "Error", ("Form is invalid"))
      this.showErrorMsg = true;
    }
  }
  update() {
    this.addMakeModelForm.get('PriceAfterDiscount').patchValue(this.addMakeModelForm.value.Price)
    if (this.addMakeModelForm.valid) {
      
      this.manageMakeModelService.updateDeviceMakeModel(this.addMakeModelForm.value, this.device_modal_id).subscribe(res => {
        this.getDeviceMakeModel()
        this.addMakeModelForm.reset()
        this.showErrorMsg = false;
        this.commonSettingService.toggleMoreMakeModelLevel1()
        this.commonService.showToast("success", "Success", "Updated successfully!")
        
      }, err => {

        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        
        this.showErrorMsg = true;
        this.showErrorMsg = false;
      })
    }
    else {
      this.commonService.showToast('error', "Error", ("Form is invalid"))
      this.showErrorMsg = true;
    }
  }
  close() {
    this.device_modal_id = null;
    this.addMakeModelForm.reset();
    this.commonSettingService.toggleMoreMakeModelLevel1()
  }


  resetForm(){
    this.addMakeModelForm = this.fb.group({
      Make: [null, Validators.required],
      Model: [null, Validators.required],
      ModelNumber: [null, Validators.required],
      NetworkType: ['GSM', Validators.required],
      SimType: [null, Validators.required],
      WiFi: [false, Validators.required],
      Data: [false, Validators.required],
      OS: [null, Validators.required],
      VoiceOnly: [false, Validators.required],
      HotspotCapable: [false, Validators.required],
      EquipmentPhoneType: [null],
      DeviceType: [null, Validators.required],
      Esim: [false],
      Price: [null, Validators.required],
      PriceAfterDiscount: [null, Validators.required],
      Color: [null, Validators.required],
      Weight: [0, Validators.required],
      Unit: [null, Validators.required],

    })
  }
}
