import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/services/auth.service";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";

@Component({
  selector: "app-application",
  templateUrl: "./application.component.html",
  styleUrls: ["./application.component.scss"],
})
export class ApplicationComponent implements OnInit {
  applicationTypes: any[] = [];
  selectedApplication: string = 'Application'
  constructor(private customerService: CustomerProfileService, private authService: AuthService) { }
  ngOnInit() {

  }
  
  ngAfterViewInit(): void {
    if (this.authService.getMingleAccessToken || this.authService.getToken) {
      this.getApplicationsType()
    }
  }
  async getApplicationsType() {
    const response: any = await this.customerService.getSideMenuTypes();
    if (response?.Status) {
      this.applicationTypes = response?.data[0]?.resource_types[1]?.resources;
      console.log("menusData: ", this.applicationTypes);
    }
  }
  canCheck(applicationType: any) {
    console.log('canCheck:', applicationType)
    if (applicationType?.resource_permission?.CanView) {
      //url logic
    }
  }
  selectApplication(application: string): void {
    this.selectedApplication = application
  }
}
