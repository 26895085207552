import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { CommonService } from "src/app/shared/services/common.service";
import { NavService } from "src/app/shared/services/nav.service";
import { environment } from "src/environments/environment.dev";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private navService: NavService
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() { }

  async login() {
    try {
      if (this.loginForm.valid) {
        let data = {
          "userName": this.loginForm.value.email,
          "password": this.loginForm.value.password,
          "companyId": "UNITYCRM0021"
        }
        this.commonService.startLoader()
        const res: any = await this.authService.login(data)
        if (res.Status) {
          this.authService.setToken(res.Token)
          this.authService.setUser(res.data[0]);
          this.authService.setRefreshToken(res.data[0]?.refreshToken);
          localStorage.setItem('Applications', JSON.stringify(res.data[0]?.applications))
          // this.setBaseUrl(res.data[0]?.applications);
          this.commonService.stopLoader()
          // this.loadSideBarmenu()
          // this.router.navigateByUrl(res.data[0].redirect_path ?? '/dashboard')
        } else {
          this.commonService.showToast("error", "Error", res.message);
          this.commonService.stopLoader()
        }
      }
    } catch (err) {
      this.commonService.stopLoader()
      this.commonService.showToast("error", "Error", err?.error?.message)
    }
  }

  loginWithMingleandStoreTokens(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          "userName": this.loginForm.value.email,
          "password": this.loginForm.value.password,
          "companyId": "UNITYCRM0021"
        };
        this.authService.loginWithMingle(data).subscribe(
          (res: any) => {
            if (res.Status) {
              this.authService.setMingleUserDetail(res.data[0]);
              this.authService.setMingleAccessToken(res.Token);
              this.authService.setMingleRefreshToken(res.data[0]?.refreshToken);
              localStorage.setItem('Applications', JSON.stringify(res.data[0]?.applications));
              resolve(true);
            } else {
              this.updateApplicationsAfterFailedMingleLogin();
              resolve(false);
            }
          },
          (error) => {
            console.error('Mingle login failed:', error);
            this.updateApplicationsAfterFailedMingleLogin();
            resolve(false);
          }
        );
      } catch (err) {
        console.error('Error in loginWithMingleandStoreTokens:', err);
        this.updateApplicationsAfterFailedMingleLogin();
        reject(false);
      }
    });
  }
  

  async loginSubmit() {
    const currentApplication = localStorage.getItem("currentApplication");
    if (currentApplication === "Mingle") {
      localStorage.setItem("BaseUrl", environment.Prepaid_Mingle_API);
      await this.loginWithMingleandStoreTokens();
      await this.loginUnityIfNeeded();
      this.loadSideBarmenu();
      this.router.navigateByUrl('/dashboard');
    } else {
      localStorage.setItem("BaseUrl", environment.AQSATEL_API);
      await this.login();
      await this.loginMingleIfNeeded();
      this.loadSideBarmenu();
      this.router.navigateByUrl('/dashboard');
    }
  }
  

  // setBaseUrl(data: any) {
  //   let baseUrl = environment.AQSATEL_API;

  //   // Check body tag class
  //   const bodyClass = document.body.classList.contains('company-mingle');
  //   if (data) {
  //     const isMingleActive = data.find(app => app.AppName === 'Mingle' && app.Access === 1);
  //     const isUnityActive = data.find(app => app.AppName === 'Unity' && app.Access === 1);
  //     if (isMingleActive) {
  //       this.loginWithMingleandStoreTokens()
  //     }
  //     if (bodyClass) {
  //       baseUrl = environment.Prepaid_Mingle_API
  //     }
  //   }
  //   localStorage.setItem("BaseUrl", baseUrl);
  // }
  // async loginSubmit() {
  //   try {
  //     if (this.loginForm.valid) {
  //       let data = {
  //         "userName": this.loginForm.value.email,
  //         "password": this.loginForm.value.password,
  //         "companyId": "UNITYCRM0021"
  //       }
  //       const res: any = await this.authService.login(data)
  //       if (res.Status) {
  //         this.authService.setToken(res.Token)
  //         this.authService.setUser(res.data[0]);
  //         this.authService.setRefreshToken(res.data[0]?.refreshToken);
  //         localStorage.setItem('Applications', JSON.stringify(res.data[0]?.applications))
  //         // this.setBaseUrl(res.data[0]?.applications);
  //         this.commonService.stopLoader()
  //         // this.loadSideBarmenu()
  //         // this.router.navigateByUrl(res.data[0].redirect_path ?? '/dashboard')
  //       } else {
  //         this.commonService.showToast("error", "Error", res.message);
  //         this.commonService.stopLoader()
  //       }
  //     }
  //   } catch (err) {
  //     this.commonService.stopLoader()
  //     this.commonService.showToast("error", "Error", err?.error?.message)
  //   }
  // }
  setBaseUrl(data: any) {
    let baseUrl = environment.AQSATEL_API;
    if (data) {
      const isMingleActive = data.find(app => app.AppName === 'Mingle' && app.Access === 1);
      const isUnityActive = data.find(app => app.AppName === 'Unity' && app.Access === 1);
      if (isMingleActive) {
        this.loginWithMingleandStoreTokens()
      }
      if (isMingleActive && !isUnityActive) {
        baseUrl = environment.Prepaid_Mingle_API;
      } else if (!isMingleActive && !isUnityActive) {
        baseUrl = environment.AQSATEL_API;
      }
    }
    localStorage.setItem("BaseUrl", baseUrl);
  }


  private updateApplicationsAfterFailedMingleLogin() {
    let applications = JSON.parse(localStorage.getItem('Applications') || '[]');
    const updatedApplications = applications.map(app =>
      app.AppName === 'Mingle' ? { ...app, Access: 0 } : app
    );
    localStorage.setItem('Applications', JSON.stringify(updatedApplications));
  }
  showPassword() {
    this.show = !this.show
  }
  async loadSideBarmenu() {
    await this.navService.sideMenuInitilize();
    this.navService.getSideMenuBar();
  }

  async loginUnityIfNeeded() {
    const applications = JSON.parse(localStorage.getItem('Applications') || '[]');
    console.log(applications);
    const isUnityActive = applications.find(app => app.AppName === 'Unity' && app.Access === 1);
    console.log(isUnityActive);
    if (isUnityActive) {
      await this.login();
    }
  }

  async loginMingleIfNeeded() {
    const applications = JSON.parse(localStorage.getItem('Applications') || '[]');
    const isMingleActive = applications.find(app => app.AppName === 'Mingle' && app.Access === 1);

    if (isMingleActive) {
      await this.loginWithMingleandStoreTokens();
    }
  }
}
