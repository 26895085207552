import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import status from './../../../mocks/status'
import tableData from './../../../mocks/review';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '../services/user-agent.service';
import { ManageComboService } from '../services/manage-combo.service';
import { CustomerSettingService } from 'src/app/shared/services/customer-settings';
import { environment } from 'src/environments/environment.dev';

declare var bootstrap: any; 
@Component({
  selector: 'app-user_settings_dashboard',
  templateUrl: './user_settings_dashboard.component.html',
  styleUrls: ['./user_settings_dashboard.component.scss']
})
export class UserSettingsDashboard implements OnInit,AfterViewChecked{

  @ViewChild('popoverElement') popoverElement!: ElementRef<HTMLDivElement>;
  public settingInfo:{
    orderDetails:any,
    usermanagementDetails:any,
    customerSettingDetails:any,
    groupManagementDetails:any,
    comboManagementDetails:any,
    otherSettingsDetails:any
}
@Input() public code:any;
showManageUser:boolean = false
public manageUserDetails:any
public manageAgentDetails:any;
public accountChangeHistory:any
public groupUserDetails:any
public groupChangeHistory:any
public settingsInfoLoaded:boolean=false
public settingDetails:any
public companyName :any
  constructor(private commonService:CommonService,
    private fb:FormBuilder,
    public manageComboService: ManageComboService,
    private userAgentService:UserAgentService,
  private customerSettingService:CustomerSettingService, private cd:ChangeDetectorRef){
    this.settingInfo = {
      orderDetails: [],
      usermanagementDetails: [],
      customerSettingDetails: [],
      groupManagementDetails: [],
      comboManagementDetails: [],
      otherSettingsDetails: []
    };
  }

  async ngOnInit(){
    this.setBreadCrumb();
    this.initPopovers();
    this.getTabsInfo();
    this.displayCompanyName()
  }
  displayCompanyName(){
    
    this.companyName = localStorage.getItem("BaseUrl") === environment.Prepaid_Mingle_API ? 'Mingle Mobile' : 'Unity Wireless'
  }

ngAfterViewChecked(): void {
  this.manageUserDetails= this.settingInfo?.usermanagementDetails[0]?.children[0];
  this.manageAgentDetails=this.settingInfo?.usermanagementDetails[0]?.children[1];
  this.accountChangeHistory=this.settingInfo?.usermanagementDetails[0]?.children[2];
  this.groupUserDetails=this.settingInfo?.groupManagementDetails[0]?.children[0];

  // console.log('user information: ', this.manageUserDetails)
  this.cd.detectChanges()
}
detail() {
  console.log("funtion call")
  this.showManageUser = true;

}


closeModal() {
this.showManageUser = false; // Hides the modal
}
  showPopover() {
    const element = this.popoverElement.nativeElement as HTMLElement;
    bootstrap.Popover.getInstance(element).show();
  }
  initPopovers() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });
  }
  
  hidePopover() {
    const element = this.popoverElement.nativeElement as HTMLElement;
    bootstrap.Popover.getInstance(element).hide();
  }

  
setBreadCrumb() {
  this.commonService.setbreadCrumb({
    "title": "Settings",
    // "items": ['#'],
    "active_item": "Settings"
  })
}

async getTabsInfo(){
  try {
    this.settingsInfoLoaded=true
    const res: any = await this.customerSettingService.getResouceDetails();
    if (res?.Status && res?.data.length>0) {
      this.settingDetails=res?.data
      console.log('setting infr:',this.settingDetails)
      let settingsTabLength = res?.data[0]?.resource_types[0]?.resources.length;
      for (let i = 0; i < settingsTabLength; i++) {

        const resourceName = res?.data[0]?.resource_types[0]?.resources[i]?.Name;
        const resourceData = res?.data[0]?.resource_types[0]?.resources[i];

        switch (resourceName) {
          case 'S_NEW_ORDER':
            this.settingInfo.orderDetails.push(resourceData);
            break;
          case 'S_USER_MANAGEMENT':
            this.settingInfo.usermanagementDetails.push(resourceData);
            break;
          case 'S_CUSTOMER_SETTINGS':
            this.settingInfo.customerSettingDetails.push(resourceData);
            break;
          case 'S_GROUP_MANAGEMENT':
            this.settingInfo.groupManagementDetails.push(resourceData);
            break;
          case 'S_COMBO_MANAGEMENT':
            this.settingInfo.comboManagementDetails.push(resourceData);
            break;
          case 'S_OTHER_SETTINGS':
            this.settingInfo.otherSettingsDetails.push(resourceData);
            break;
        }
        this.settingsInfoLoaded=false
      }
      console.log('settijng Info: ',this.settingInfo)
      this.settingsInfoLoaded=false
    }else{
      this.settingDetails = [];
    }
  } catch (error) {
    console.error('Error:', error);
    this.settingsInfoLoaded=false
  }
}


}
