import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable ,Injector,NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { environment } from 'src/environments/environment.dev';
import Swal from 'sweetalert2';
import { InactivityService } from './inactivity.service';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  
  api: string = environment.BASE_API
  private inactivityService:InactivityService

  @LocalStorage()
  private refreshToken;
  isRefreshTokenDialogAlreadyDisplayed=false
  isExpiredTokenDialogAlreadyDisplayed=false
  isCustomerLogin=false
  public isRefreshingToken = false;
  constructor(
    private http: HttpClient,
    public router: Router,
    private localStorage: LocalStorageService,
    private injector: Injector, 
    private ngZone: NgZone,
    private httpService: HttpService
  ) {
    this.inactivityService = this.injector.get(InactivityService);
  }

  // async login(data) {
  //   try {
  //     const token = 'eyJpdiI6ImpxMS9SemZmZDU2MXhTWmVlT1kxVnc9PSIsInZhbHVlIjoiNGlGLzJ1cmYvS21XR0svemhvd2VGeUZlS1Rvc3psRFZoQzNNQlY0VHhGbkJyU3JpeEN4Rnl2eURhT0JCSi9UOSIsIm1hYyI6IjBmMDU0ZDE3Zjk4YmJlNThiZmRiOGIzYjk0MGFhYjM5ZWY5MDhlYjkzZmMxNGZiOTM5M2RiMDRiMzAwNzk1YmUiLCJ0YWciOiIifQ='
  //     const headers = new HttpHeaders({
  //       'Authorization': `Bearer ${token}`
  //     });
  //     const response: any = await this.http.post('https://d84f-180-151-6-130.ngrok-free.app/api/Authorization/authLogin', data,{headers}).toPromise();
  //     if(response?.Status){
  //       this.isCustomerLogin=true
  //       this.getInactivityService().start();
  //       // this.startTokenChecks();
  //     }
  //     return response;
  //   } catch (err) {
  //     this.isCustomerLogin=false
  //     throw err;
  //   }
  // }
  async login(data) {
    try {
      const response: any = await this.http.post(environment.BASE_API + 'api/Authorization/authLogin', data).toPromise();
      if(response?.Status){
        this.isCustomerLogin=true
        this.getInactivityService().start();
        // this.startTokenChecks();
      }
      return response;
    } catch (err) {
      this.isCustomerLogin=false
      throw err;
    }
  }

  loginWithMingle(data) {
    try {
      return this.http.post(environment.Prepaid_Mingle_API + 'api/Authorization/authLogin', data);
    } catch (err) {
      throw Error(err)
    }
  }

  // startTokenChecks(){
  //   setTimeout(() => {
  //     setInterval(() => {
  //       if (this.isRefreshTokenAboutToExpire() && this.isCustomerLogin) {
  //         if (!this.isRefreshTokenDialogAlreadyDisplayed) {
  //           this.showRefreshTokenDialog();
  //         }
  //       }
  //     }, 1000);
  //     setInterval(() => {
  //       if (this.isTokenExpired() && this.isCustomerLogin) {
  //         if (!this.isExpiredTokenDialogAlreadyDisplayed) {
  //           this.showTokenExpiredDialog();
  //         }
  //       }
  //     }, 1000);
  //   }, 200);
  // }

  // startTokenChecks() {
  //   const refreshTokenExpiry = this.localStorage.retrieve("refreshTokenExpiry");
  //   if (refreshTokenExpiry ) {
  //     const expiryDate = new Date(refreshTokenExpiry);
  //     const currentTime = new Date();
  //     console.log(expiryDate.getTime() , currentTime.getTime() , (environment.JWT_EXPIRATION_WARNING_TIME_IN_MINUTES * 60 * 1000))
  //     const timeUntilWarning = expiryDate.getTime() - currentTime.getTime() - (environment.JWT_EXPIRATION_WARNING_TIME_IN_MINUTES * 60 * 1000);
  //     const timeUntilExpiration = expiryDate.getTime() - currentTime.getTime();
  //     console.log(timeUntilExpiration,timeUntilWarning)
  //     if (timeUntilWarning > 0) {
  //       this.refreshTimeout = setTimeout(() => {
  //         this.showRefreshTokenDialog();
  //       }, timeUntilWarning);
  //     } else {
  //       this.showRefreshTokenDialog();
  //     }
  //     if (timeUntilExpiration > 0) {
  //       this.expireTimeout = setTimeout(() => {
  //         this.showTokenExpiredDialog();
  //       }, timeUntilExpiration);
  //     } else {
  //       this.showTokenExpiredDialog();
  //     }
  //   }
  // }
  
  private getInactivityService(): InactivityService {
    if (!this.inactivityService && this.isCustomerLogin) {
      this.inactivityService = this.injector.get(InactivityService);
    }
    return this.inactivityService;
  }

  generateTokenByRefereshToken() {
    let apiBaseUrl = localStorage.getItem('BaseUrl') ??   environment.BASE_API
      let url = apiBaseUrl + 'api/Authorization/AccessToken';
      let data = {
        "userID": "1",
        "companyId": "UNITYCRM0021",
        "refreshToken": apiBaseUrl===environment.AQSATEL_API ? this.refreshToken : this.getMingleRefreshToken()
      }
      return this.http.post(url, data)
  }


  async generateRefreshTokenByRefereshToken() {
    if (this.isRefreshingToken) {
      return; // Skip if already refreshing
    }
    this.isRefreshingToken = true;
    try {
      let apiBaseUrl = localStorage.getItem('BaseUrl') ?? environment.BASE_API

      let url = apiBaseUrl + 'api/Authorization/RefreshToken';
      let data = {
        "userID": "1",
        "companyId": "UNITYCRM0021",
        "refreshToken": this.refreshToken
      }
      const res:any = await this.http.post(url, data).toPromise();
      this.setRefreshToken(res?.data[0]?.refreshToken)
    } catch (err) {
      console.log('err',err)
      this.logout();
      throw Error(err?.error?.message);
    } finally {
      this.isRefreshingToken = false; // Reset the flag after completion
    }
  }

  setToken(token) {
    this.localStorage.store('token', token)
  }

  get getToken() {
    return this.localStorage.retrieve('token')
  }

  setMingleAccessToken(token) {
    localStorage.setItem('mingleAccesstoken', token)
  }

  get getMingleAccessToken() {
    return localStorage.getItem('mingleAccesstoken')
  }

  setMingleRefreshToken(token) {
    localStorage.setItem('mingleRefreshtoken', token)
  }

  getMingleRefreshToken() {
    localStorage.getItem('mingleRefreshtoken')
  }

  setMingleUserDetail(data){
    this.localStorage.store('mingleUserDetail', data)
  }
  // getRefereshToken() {
  //   // 
  //   // return this.getUser().refreshToken
  // }

  setRefreshToken(refreshToken) {
    /**/
    this.localStorage.store('refreshToken', refreshToken)
    let timestamp=new Date()
    timestamp.setMinutes(timestamp.getMinutes()+environment.JWT_EXPIRATION_TIME_IN_MINUTES)
    this.localStorage.store('refreshTokenExpiry',timestamp.toString())
    this.isRefreshTokenDialogAlreadyDisplayed=false
    this.isExpiredTokenDialogAlreadyDisplayed=false
    // this.resetTokenChecks()
  }

  setUser(data) {
    this.localStorage.store('userDetails', data)
  }



  logout() {
    const keyToKeep = 'currentApplication'; 
    const valueToKeep = localStorage.getItem(keyToKeep);
    localStorage.clear();
    if (valueToKeep !== null) {
      localStorage.setItem(keyToKeep, valueToKeep);
    }
    this.httpService.ngOnDestroy();
    this.router.navigateByUrl('auth/login');
    this.isCustomerLogin = false;
    this.getInactivityService().stop();
    sessionStorage.clear();
  }
  isTokenExpired() {
    if(this.localStorage.retrieve("refreshTokenExpiry") ){
      let date1=new Date(this.localStorage.retrieve("refreshTokenExpiry"))
  
      let current = new Date()
      let differenceInMin = (date1.getTime() - current.getTime())/(1000*60)
      return differenceInMin <= 0.5;
    }
      return false;
  }
  private logoutUser() {
    this.ngZone.run(() => {
      Swal.fire({
        title: 'Session Expired',
        text: 'You have been logged out due to inactivity.',
        icon: 'warning',
        confirmButtonText: 'OK',
        allowOutsideClick: false, 
        allowEscapeKey: false 
      }).then(() => {
        console.clear()
        this.logout();
      });
    });
  }

  isRefreshTokenAboutToExpire(){
    if(this.localStorage.retrieve("refreshTokenExpiry")){
      let date1=new Date(this.localStorage.retrieve("refreshTokenExpiry"))
      let current = new Date()
      let differenceInMin = (date1.getTime() - current.getTime())/(1000*60)
      return differenceInMin < environment.JWT_EXPIRATION_WARNING_TIME_IN_MINUTES;
    }else{
      return false
    }

  }


  showRefreshTokenDialog(): Promise<boolean> {
    if (this.isRefreshingToken) {
      return Promise.resolve(false); // Return immediately if already in the process
    }

    this.isRefreshTokenDialogAlreadyDisplayed=true
    return Swal.fire({
      title: "Your Session is about to expire.",
      text: "Do you want to continue the session?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.isConfirmed ){
        this.generateRefreshTokenByRefereshToken()
      }
      return result.isConfirmed;
    });
  }

  
  showTokenExpiredDialog(): Promise<boolean> {
    this.isExpiredTokenDialogAlreadyDisplayed=true
    return Swal.fire({
      title: "Session Expired.",
      text: "Login again.",
      icon: 'error',
      confirmButtonText: 'Login',
      allowOutsideClick: false, 
      allowEscapeKey: false  
    }).then((result) => {
      if(result.isConfirmed){
        localStorage.clear();
        this.router.navigateByUrl('auth/login');
        console.clear()
      }
      return result.isConfirmed;
    });
  }


  // resetTokenChecks() {
  //   clearTimeout(this.refreshTimeout);
  //   clearTimeout(this.expireTimeout);
  //   // this.startTokenChecks(); 
  // }

}