import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavService } from '../../../../services/nav.service';

@Component({
  selector: 'app-change-company',
  templateUrl: './change_company.component.html',
  styleUrls: ['./change_company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  public companyChange: boolean = false;
  public Company: any[] = [
    {
      name: 'Unity',
      url: environment.AQSATEL_API,
      icon: 'stock-mobile'
    },
    {
      name: 'Mingle',
      url: environment.Prepaid_Mingle_API,
      icon: 'brand-wire'
    }
  ];

  public selectedCompany: any = {
    name: 'Unity',
    url: environment.AQSATEL_API,
    icon: 'stock-mobile'
  };
  public displayDropdowns: boolean = false;

  constructor(public navServices: NavService) { }

  ngOnInit() {
    setTimeout(() => {
      this.checkApplications();
    }, 1500);
  }

  checkApplications() {
    const localApplications = JSON.parse(localStorage.getItem('Applications')) || [];
  
    const unityAccess = localApplications.some(app => app.AppName === "Unity" && app.Access === 1);
    const mingleAccess = localApplications.some(app => app.AppName === "Mingle" && app.Access === 1);
    this.displayDropdowns = unityAccess && mingleAccess;
  
    // Get BaseUrl from localStorage
    let BaseUrl = localStorage.getItem("BaseUrl");
    if (BaseUrl) {
      const matchingCompany = this.Company.find(company => company.url === BaseUrl);
      if (matchingCompany) {
        this.selectedCompany = matchingCompany;
        this.changeClass(matchingCompany)
      }
    }
  }

  changeCompany(company) {
    this.selectedCompany = company;
    localStorage.setItem("BaseUrl", company?.url);
    localStorage.setItem("currentApplication", company?.name);
    window.location.reload();
  }
  
  changeClass(company){
        // Get the company's name and normalize it for use as a class
        const companyNameClass = company?.name?.replace(/\s+/g, '-').toLowerCase();
  
        // Access the body element
        const bodyElement = document.body;
      
        // Remove any existing company name classes from the body
        bodyElement.classList.forEach(cls => {
          if (cls.startsWith('company-')) {
            bodyElement.classList.remove(cls);
          }
        });
      
        // Add the new company name class to the body
        if (companyNameClass) {
          bodyElement.classList.add(`company-${companyNameClass}`);
        }
  }

}
