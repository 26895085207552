import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service'; // Import LoaderService

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private loaderService: LoaderService // Inject LoaderService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/authLogin')) {
      return next.handle(request);
    }
    const token = this.getTokenBasedOnUrl(request.url);

    if (token) {
      request = this.addToken(request, token);
    }

    if (this.authService.isTokenExpired()) {
      if (!this.loaderService.expiredTokenModalOpen) { // Check if the modal is already open
        this.loaderService.setExpiredTokenModalOpen(true); // Set modal state
        this.authService.showTokenExpiredDialog().finally(() => {
          this.loaderService.setExpiredTokenModalOpen(false); // Reset modal state when dialog closes
        });
      }
    }
    if (this.authService.isRefreshTokenAboutToExpire() && !this.authService.isTokenExpired()) {
      this.authService.generateRefreshTokenByRefereshToken();
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && request.url.includes('/RefreshToken')) {
          this.authService.logout();
          return EMPTY;
        }
        return throwError(error);
      })
    );
  }

  private getTokenBasedOnUrl(url: string): string | null {
    if (url.startsWith(environment.AQSATEL_API)) {
      return this.localStorage.retrieve('token');
    } else if (url.startsWith(environment.Prepaid_Mingle_API)) {
      return localStorage.getItem('mingleAccesstoken');
    }
    return null;
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.generateTokenByRefereshToken().pipe(
      switchMap((res: any) => {
        if (request.url.startsWith(environment.AQSATEL_API)) {
          this.localStorage.store('token', res.Token);
        } else if (request.url.startsWith(environment.Prepaid_Mingle_API)) {
          localStorage.setItem('mingleAccesstoken', res.Token);
        }
        const clonedRequest = this.addToken(request, res.Token);
        return next.handle(clonedRequest);
      }),
      catchError((error) => {
        this.authService.logout();
        return throwError(error);
      })
    );
  }
}
