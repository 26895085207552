<div class="d-flex justify-content-between">
  <p class="f-18 cursor-pointer" (click)="commonSettingService.moreMakeModelSelectedTab('MANAGE_MAKE_MODELS')"><i
      class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{manageMakeModelsDetails?.DisplayName}}</p>
</div>

<div class="customizer-contain" [ngClass]="{ open: commonSettingService.moreMakeModel == true }"
  [ngStyle]="{ 'width': commonSettingService.popup1WidthMakeModel }">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header">
      <h5 class="mb-0">List of Make & Models</h5>
      <i class="icofont-close icon-close" (click)="commonSettingService.toggleMoreMakeModel()"></i>
    </div>
    <div class="customizer-header">
      <div class="row">
        <div class="col-md-6 mt-2">
          <input type="text" class="form-control" placeholder="Search">
        </div>
        <div class="col-md-6 mt-2">
          <button class="btn btn-primary" (click)="commonSettingService.moreMakeModelSelectedTab1('Line Information')">+
            Add</button>
        </div>
      </div>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div class="tab-pane fade" [ngClass]="{ 'active show': commonSettingService.moreMakeModel}" id="c-pills-home"
        role="tabpanel" aria-labelledby="c-pills-home-tab">
        <div class="notes-panel" [ngClass]="{'open-notes': true}">
          <div class="wrapper d-flex bg-white">
            <div class="right-panel w-100">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 mx-auto">
                    <div class="table-responsive marginB2" style="height: 500px;">

                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Make </th>
                            <th scope="col">Model</th>
                            <th scope="col">ModelNumber</th>
                            <th scope="col">NetworkType</th>
                            <th scope="col">SimType</th>
                            <th scope="col">WiFi</th>
                            <th scope="col">Data</th>
                            <th scope="col">OS</th>
                            <th scope="col">VoiceOnly</th>
                            <th scope="col">HotspotCapable</th>
                            <th scope="col">EquipmentPhoneType</th>
                            <th scope="col">DeviceType</th>
                            <th scope="col">Esim</th>
                            <th scope="col">Price</th>
                            <th scope="col">PriceAfterDiscount</th>
                            <th scope="col">Color</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Unit</th>
                            <th scope="col">IsActive</th>
                            <th scope="col">IsDeleted</th>
                            <th scope="col">CreatedDate</th>

                            <th scope="col">DeviceActualCost</th>
                            <th scope="col">DeviceCopayCost</th>
                            <th scope="col">DeviceExpectedRate</th>
                            <th scope="col">SimTypeName</th>
                            <th scope="col">Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of deviceMakeModelList">

                            <td>
                              <ngb-highlight [result]="item.Make" [term]="'Make'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Model" [term]="'Model'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.ModelNumber" [term]="'ModelNumber'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.NetworkType" [term]="'NetworkType'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.SimType" [term]="'SimType'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.WiFi" [term]="'WiFi'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Data" [term]="'Data'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.OS" [term]="'OS'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.VoiceOnly" [term]="'VoiceOnly'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.HotspotCapable" [term]="'HotspotCapable'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.EquipmentPhoneType"
                                [term]="'EquipmentPhoneType'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.DeviceType" [term]="'DeviceType'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Esim" [term]="'Esim'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Price" [term]="'Price'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.PriceAfterDiscount"
                                [term]="'PriceAfterDiscount'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Color" [term]="'Color'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Weight" [term]="'Weight'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.Unit" [term]="'Unit'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.IsActive" [term]="'IsActive'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.IsDeleted" [term]="'IsDeleted'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.CreatedDate" [term]="'CreatedDate'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.DeviceActualCost"
                                [term]="'DeviceActualCost'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.DeviceCopayCost" [term]="'DeviceCopayCost'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.DeviceExpectedRate"
                                [term]="'DeviceExpectedRate'"></ngb-highlight>
                            </td>
                            <td>
                              <ngb-highlight [result]="item.SimTypeName" [term]="'SimTypeName'"></ngb-highlight>
                            </td>
                            <td>
                              <i class="fa-solid fa-pen-to-square" (click)="edit(item)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="pull-right p-20 d-flex">
                        <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                          (change)="onChangeNumberOfPage()">
                          <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                        </select>
                        <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true"
                          [collectionSize]="totalItems" [(page)]="currentPage" (pageChange)="pageChange($event)"
                          [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                          <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
                      </div>

                    </div>
                    <br><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="customizer-contain" [ngClass]="{ open: commonSettingService.moreMakeModelLevel1 == true }"
  [ngStyle]="{ 'width': commonSettingService.popup2WidthMakeModel}">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header">
      <h5 class="mb-0" *ngIf="!device_modal_id">Add New Make & Models</h5>
      <h5 class="mb-0" *ngIf="device_modal_id">Edit Make & Models</h5>
      <i class="icofont-close icon-close" (click)="close()"></i>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div class=" m-3">
        <form [formGroup]="addMakeModelForm">
          <div class="card p-3" style="height: 70vh;
            overflow-y: auto;">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Make*</label>
                    <input type="text" formControlName="Make" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Model*</label>
                    <input type="text" formControlName="Model" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Model Number*</label>
                    <input type="text" formControlName="ModelNumber" class="form-control">
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Sim Type*</label>
                    <select class="form-control form-select" formControlName="SimType">
                      <option value="Micro">Micro</option>
                      <option value="Nano">Nano</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>OS*</label>
                    <select class="form-control form-select" formControlName="OS">
                      <option value="IOS">IOS</option>
                      <option value="Android">Android</option>
                    </select>

                  </div>
                </div>

                <!-- <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Equipment Phone Type*</label>
                    <input type="text" formControlName="EquipmentPhoneType" class="form-control">
                  </div>
                </div> -->
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Device Type*</label>
                    <select class="form-control form-select" formControlName="DeviceType">
                      <option value="Tablet">TABLET</option>
                      <option value="Phone">Phone</option>
                    </select>
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Price*</label>
                    <input type="number" formControlName="Price" class="form-control">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Color*</label>
                    <input type="text" formControlName="Color" class="form-control">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Unit*</label>
                    <input type="text" formControlName="Unit" class="form-control">
                  </div>
                </div>



                <div class="col-md-3 my-auto">
                  <div class="form-group mt-2">
                    <label>Esim</label>
                    <input type="checkbox" class="ms-2" formControlName="Esim">
                  </div>
                </div>

                <div class="col-12 my-2 text-center" *ngIf="addMakeModelForm.invalid && showErrorMsg">
                  <p class="text-danger">Please fill required fields.</p>
                </div>
                <div class="col-md-12 text-center my-3">
                  <button type="submit" (click)="submit()" *ngIf="!device_modal_id"
                    class="btn btn-primary">Next</button>
                  <button type="submit" (click)="update()" *ngIf="device_modal_id"
                    class="btn btn-primary">Update</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>