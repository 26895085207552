<div class="position-relative">
    <button class="custom-dropdown" (click)="toggleDropdown($event)">
        <div class="d-flex align-items-center">
            <span class="text">
                {{ title }} <!-- Display the updated title -->
            </span>
            <span class="icon-wrapper" *ngIf="icon != 'plus'">
                <i class="fa fa-chevron-down" *ngIf="!isDropdownOpen"></i>
                <i class="fa fa-chevron-up" *ngIf="isDropdownOpen"></i>
            </span>
            <span class="icon-wrapper" *ngIf="icon == 'plus'">
                <span class="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M10.75 5.75c0-.414-.336-.75-.75-.75s-.75.336-.75.75v3.5h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5Z"></path>
                    </svg>
                </span>
            </span>
        </div>
    </button>
    <div class="dropdown-content" *ngIf="isDropdownOpen">
        <div>
            <ul>
                <li *ngFor="let item of optionList; let i = index;" class="list-items">
                    <div class="form-check checkbox checkbox-success mb-0">
                        <input [id]="'checkbox-primary' + i" type="checkbox" class="form-check-input"
                            [checked]="isSelected(item)" (change)="toggleSelection(item, $event.target['checked'])">
                        <label [for]="'checkbox-primary' + i" class="form-check-label m-0 w-100">{{item[label]}}</label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
