<div class="container-fluid">
    <div class="card-header mt-0 mb-3  d-flex align-items-center gap-2 " (click)="back()">
        <i class="fa-solid fa-arrow-left" style="font-size: 20px; cursor: pointer;"></i>
        <div class="heading">
            <h4 class="mb-0">User Informations </h4>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">

            <div class="mb-3">
                <h5 class="card-title">User List <span class="text-muted fw-normal ms-2">(Total: {{totalusers
                        }})</span></h5>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div class="input-group col-md-5 px-0">
                    <input type="text" class="form-control" value="" (keyup)="filterdData($event)">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
                    </span>
                </div>
                <div class="col-md-2 text-end mx-0 px-0">
                    <a routerLink="/edit-users" data-bs-toggle="modal" data-bs-target=".add-new"
                        class="btn btn-primary text-nowrap"><i class="bx bx-plus me-1"></i> Add New</a>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 50px;">
                                <div class="form-check font-size-16">
                                    <input type="checkbox" class="form-check-input" id="selectAll" />
                                    <label class="form-check-label" for="selectAll"></label>
                                </div>
                            </th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Application</th>
                            <th scope="col" style="width: 150px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of userList">
                            <th scope="row">
                                <div class="form-check font-size-16">
                                    <input type="checkbox" class="form-check-input" id="userCheck{{user.id}}" />
                                    <label class="form-check-label" for="userCheck{{user.id}}"></label>
                                </div>
                            </th>
                            <td><img src="https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=612x612&w=0&k=20&c=dhV2p1JwmloBTOaGAtaA3AW1KSnjsdMt7-U_3EZElZ0="
                                    alt="" class="avatar-sm rounded-circle me-2" />{{ user.name }}</td>
                            <td>{{ user.userName }}</td>
                            <td>{{ user.email }}</td>
                            <td>
                                <span class="badge text-dark" [ngClass]="{
                                    'badge-soft-success': user?.usergroup?.[0]?.Code == 'REPORTS',
                                    'badge-soft-info': ['ADMINISTRATOR', 'SUPERVISOR','FULFILLMENT','MANAGER'].includes(user?.usergroup?.[0]?.Code),
                                    'badge-soft-danger': ['CUSTOMER', 'TEAM_LEADER','ANALYTICS'].includes(user?.usergroup?.[0]?.Code),
                                    'badge-soft-primary': user?.usergroup?.[0]?.Code == 'CUSTOMER_SERVICE',
                                    
                                  }">
                                    {{ user?.usergroup?.[0]?.Code || '-' }}
                                </span>
                            </td>
                            <td>
                               <span class="badge badge-soft-primary mx-2"> {{ user.aqsa_tel_app_users[0]?.Name ? user.aqsa_tel_app_users[0]?.Name : '-' }}</span>
                               <span class="badge badge-soft-secondary">{{ user.aqsa_tel_app_users[1]?.Name ? user.aqsa_tel_app_users[1]?.Name : '-' }}</span>
                            </td>
                            <td>
                                <ul class="list-inline mb-0" style="cursor: pointer;">
                                    <li class="list-inline-item" (click)="editRouter(user)">
                                        <i class="fa-solid fa-pen text-primary"></i>
                                    </li>
                                    <li class="list-inline-item mx-1">
                                        <i class="fa fa-ban text-danger"></i>
                                    </li>
                                    <li class="list-inline-item dropdown mx-2">
                                        <a class="text-muted dropdown-toggle font-size-18 px-2" href="#"
                                            data-bs-toggle="dropdown"><i class="bx bx-dots-vertical-rounded"></i></a>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a class="dropdown-item" (click)="viewProfile(user.id)"> <i
                                                    class="fa-solid fa-eye"></i>View Profile</a>
                                            <!-- <a class="dropdown-item" href="#">Another action</a> -->
                                        </div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pull-right p-20 d-flex">
                    <ngb-pagination [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
                        [(page)]="currentPage" (pageChange)="pageChange($event)" [maxSize]="itemsPerPage"
                        [pageSize]="itemsPerPage">
                        <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>