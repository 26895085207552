import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ManageAgentService {
  userId: string;
  companyId: string;

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) {

  }


  getUser() {
    return this.userDetails;
  }


  saveAgent(obj) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }

  getAgentList() {
    let body = {
      companyId: "UNITYCRM0021",
    }
    let url = "api/system/agents-list"
    return this.http.get(url, body)
  }

  getAgentListNopage() {
    let body = {
      companyId: "UNITYCRM0021",
    }
    let url = "api/system/agents-list-no-page"
    return this.http.get(url, body)
  }

  updateAgent(obj: any) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }


  getDistributorList(id) {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/distributors/" + id
    return this.http.get(url, obj)
  }


  getAllcatagory() {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/getResourceCategories/"
    return this.http.get(url, obj)
  }
  
  getAllResources() {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/resources/"
    return this.http.get(url, obj)
  }

  getRetailersList(obj) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }

  getMasterAgent() {
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/masteragent"
    return this.http.get(url, obj)
  }

  getCities() {
    let url = `api/system/getCities`;
    return this.http.get(url)

  }

 createNewRes(obj) {
    let url = "api/system/resources/create"
    return this.http.post(url, obj)
  }
}
