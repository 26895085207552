import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.scss']
})
export class NewProfileComponent {

  public myProfile: UntypedFormGroup;
  public editProfile: UntypedFormGroup;
  
  constructor(private fb: UntypedFormBuilder,private router:Router,) { }

  ngOnInit(): void {
    this.myProfile = this.fb.group({
      bio: ['On the other hand, we denounce with righteous indignation'],
      email: ['', [Validators.email]],
      password: [''],
      website: [],
    });
    this.editProfile = this.fb.group({
      company: [''],
      userName: [''],
      email: ['', Validators.email],
      firstName: [''],
      lastName: [''],
      address: [''],
      city: [''],
      zipCode: [null],
      country: [''],
      about: ['']
    })
  }
  back(){
    this.router.navigateByUrl(`/manage-user`)
  }
}
