import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { CustomerProfileService } from "../../services/customer-profile.service";
import { MessageService } from "primeng/api";
import { CommonService } from "../../services/common.service";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [MessageService]
})
export class HeaderComponent implements OnInit {
  public elem: any;
  public customizer: string = "";
  toggleUserProfile:boolean = false;
  isMobileView: boolean;
  isSearchActive: boolean;

  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    public customerProfileService: CustomerProfileService,
    public messageService:MessageService,
    private commonService:CommonService
  ) { }

  ngOnInit() {
    this.elem = document.documentElement;
    this.openSearch()
    this.checkMobileView();
  }

  openSearch(){
    this.commonService.searchQuery.subscribe(query => {
      if (query) {
       this.searchToggle();
      }
      else{
        this.navServices.search = false;
        setTimeout(() => { 
          document.getElementById('searchbar').blur();
        });
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkMobileView();
  }

  checkMobileView() {
    this.isMobileView = window.innerWidth <= 768; // Adjust the value based on your breakpoint
  }

  // Toggle search view
  toggleSearch() {
    this.isSearchActive = !this.isSearchActive;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
    document.getElementById('searchbar').focus()
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  showCompanyChangeToggle() {
    this.navServices.changeCompany = !this.navServices.changeCompany;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  Customizer(val) {
    this.customizer = val;
  }

  toggleCustomerProfile() {
    this.toggleUserProfile = !this.toggleUserProfile
    this.customerProfileService.setProfileQuicklink(this.toggleUserProfile)
  }
  closeSearch() {
    this.isSearchActive = false;
  }
}
