import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';
import Swal from 'sweetalert2';
import { InactivityService } from './inactivity.service';
import { environment } from 'src/environments/environment.dev';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private inactivityService: InactivityService

  @LocalStorage()
  private userDetails;
  @LocalStorage()
  private currentCompany;
  isRefreshTokenDialogAlreadyDisplayed = false
  isExpiredTokenDialogAlreadyDisplayed = false
  isCustomerLogin = false
  public isRefreshingToken = false;
  public company = null;
  public buyflow:boolean=false;
  constructor(
    private http: HttpClient,
    public router: Router,
    private localStorage: LocalStorageService,
    private injector: Injector,
    private ngZone: NgZone,
    private httpService: HttpService
  ) {
    this.inactivityService = this.injector.get(InactivityService);
  }


  async login(data) {
    try {
      const response: any = await this.http.post(environment.CAS_BASE_RUL + 'api/CAS/login', data).toPromise();
      if (response?.Status) {
        this.isCustomerLogin = true
        this.getInactivityService().start();
        // this.startTokenChecks();
      }
      return response;
    } catch (err) {
      this.isCustomerLogin = false
      throw err;
    }
  }


  private getInactivityService(): InactivityService {
    if (!this.inactivityService && this.isCustomerLogin) {
      this.inactivityService = this.injector.get(InactivityService);
    }
    return this.inactivityService;
  }

  getCompanyName(){
    if(!this.company){
      this.company = localStorage.getItem('currentCompany');
    }
    return this.company;
  }
  getBuyFlowHost(companyName:string){
    if(!this.buyflow){
      this.buyflow=environment[`${companyName}_BUYFLOW`];
    }
    return this.buyflow;
  }

  generateTokenByRefereshToken() {
    const key = this.getCompanySpecificKey('Refreshtoken');
    const Refreshtoken = localStorage.getItem(key);
    let url = environment.CAS_BASE_RUL +'api/CAS/tokenRefresh';
    let data = {
      "userID": this.userDetails?.userID,
      "companyId": localStorage.getItem("currentCompany"),
      "refreshToken": Refreshtoken,
      "userName":this.userDetails?.userName,
    }
    return this.http.post(url, data)
  }

  // "userName": "test_username12",
  //     "companyId": "unity",
  //     "userID": 8938,
  async generateRefreshTokenByRefereshToken() {
    if (this.isRefreshingToken) {
      return; // Skip if already refreshing
    }
    const key = this.getCompanySpecificKey('Refreshtoken');
    const Refreshtoken = localStorage.getItem(key);
    this.isRefreshingToken = true;
    try {
      let apiBaseUrl = localStorage.getItem('BaseUrl')
      let url = apiBaseUrl + 'api/Authorization/RefreshToken';
      let data = {
        "userID": this.userDetails.userID,
        "companyId": "UNITYCRM0021",
        "refreshToken": Refreshtoken
      }
      const res: any = await this.http.post(url, data).toPromise();
      const currentCompany = localStorage.getItem('currentCompany') || 'unity';
      this.setRefreshToken(currentCompany,res?.data[0]?.refreshToken)
    } catch (err) {
      console.log('err', err)
      this.logout();
      throw Error(err?.error?.message);
    } finally {
      this.isRefreshingToken = false; // Reset the flag after completion
    }
  }

  setToken(companyId, token) {
    // if(companyId.)
    this.localStorage.store(companyId + '-Token', token)
  }

  get getToken() {
    return this.localStorage.retrieve('token')
  }


  setRefreshToken(companyId, refreshToken) {
    this.localStorage.store(companyId + '-Refreshtoken', refreshToken)
    let timestamp = new Date()
    timestamp.setMinutes(timestamp.getMinutes() + environment.JWT_EXPIRATION_TIME_IN_MINUTES)
    this.localStorage.store(companyId + '-RefreshTokenExpiry', timestamp.toString())
    this.isRefreshTokenDialogAlreadyDisplayed = false
    this.isExpiredTokenDialogAlreadyDisplayed = false

  }


  setUser(companyId, data) {
    this.localStorage.store(companyId + '-userDetails', data)
  }

  setBaseUser(data) {
    this.localStorage.store('userDetails', data)
  }



  logout() {
    const keyToKeep = 'currentCompany';
    const valueToKeep = localStorage.getItem(keyToKeep);
    localStorage.clear();
    if (valueToKeep !== null) {
      localStorage.setItem(keyToKeep, valueToKeep);
    }
    this.httpService.ngOnDestroy();
    this.router.navigateByUrl('auth/login');
    this.isCustomerLogin = false;
    this.getInactivityService().stop();
    sessionStorage.clear();
  }

  getCompanySpecificKey(key: string): string {
    const currentCompany = localStorage.getItem('currentCompany') || 'unity';
    return `tibss.${currentCompany}-${key}`;
  }

  isTokenExpired(): boolean {
    const key = this.getCompanySpecificKey('RefreshTokenExpiry');
    const expiryTime = localStorage.getItem(key);
    console.log(expiryTime);
    if (expiryTime) {
      const expiryDate = new Date(expiryTime);
      const currentDate = new Date();
      const differenceInMin = (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60);
      return differenceInMin <= 0.5;
    }
    return false;
  }

  isRefreshTokenAboutToExpire(): boolean {
    const key = this.getCompanySpecificKey('RefreshTokenExpiry');
    const expiryTime = localStorage.getItem(key);
    if (expiryTime) {
      const expiryDate = new Date(expiryTime);
      const currentDate = new Date();
      const differenceInMin = (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60);
      return differenceInMin < environment.JWT_EXPIRATION_WARNING_TIME_IN_MINUTES;
    }
    return false;
  }

  showRefreshTokenDialog(): Promise<boolean> {
    if (this.isRefreshingToken) {
      return Promise.resolve(false); // Return immediately if already in the process
    }

    this.isRefreshTokenDialogAlreadyDisplayed = true
    return Swal.fire({
      title: "Your Session is about to expire.",
      text: "Do you want to continue the session?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.generateRefreshTokenByRefereshToken()
      }
      return result.isConfirmed;
    });
  }

  showTokenExpiredDialog(): Promise<boolean> {
    this.isExpiredTokenDialogAlreadyDisplayed = true
    return Swal.fire({
      title: "Session Expired.",
      text: "Login again.",
      icon: 'error',
      confirmButtonText: 'Login',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        this.router.navigateByUrl('auth/login');
        console.clear()
      }
      return result.isConfirmed;
    });
  }
}

