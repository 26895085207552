import { Component, EventEmitter, Output } from '@angular/core';
import { ManagePermissionService } from '../../services/manage-permission.service';
import { ManageAgentService } from '../../services/manage-agent.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-resources',
  templateUrl: './manage-resources.component.html',
  styleUrls: ['./manage-resources.component.scss']
})
export class ManageResourcesComponent {
  moreInfo: boolean = false;
  popup1Width;
  popup2Width;
  public check=true;
  selectedResource: any;
  selectedCategory: any;
  categoryList:any
  resourcesTypeList:any
  resourceName: string;
  showExtraInputs:boolean = false
  navIconClass: string;
  resourceDescription: string;
  urlRoute: string;
  columnDefs:any[]=[]
  form:FormGroup
  @Output() close = new EventEmitter<any>();
  constructor(private manageAgentService:ManageAgentService,private fb: FormBuilder){

  }
  resources = [
    { name: 'Resource 1', description: 'Description 1', category: 'Category 1' },
    { name: 'Resource 2', description: 'Description 2', category: 'Category 2' },
    // Add more resources as needed
];
resourcestype = [
  { id: 1, name: 'Resource 1', description: 'Description 1', category: 'Category 1' },
  { id: 2, name: 'Resource 2', description: 'Description 2', category: 'Category 2' },
  // Add more resources as needed
];

categories = [
  { id: 1, DisplayName: 'Category 1' },
  { id: 2, DisplayName: 'Category 2' },
  // Add more categories as needed
];


ngOnInit(){
  this.form = this.fb.group({
    resourceSelect: [null, Validators.required],
    resourceDescription: ['', Validators.required],
    categorySelect: [null, Validators.required],
    resourceName: [''],
    navIconClass: [''],
    urlRoute: ['']
  });
  this.getAllAgentList()
  this.getResourcesList()

  this.columnDefs = [
    { headerName: 'Resource ID', field: 'id', minWidth: 191 },
    { headerName: 'Resource Name', field: 'DisplayName', cellRenderer: this.boldRenderer },
    { headerName: 'Description', field: 'Description', cellRenderer: this.defaultRenderer },
    { headerName: 'Category', field: 'resource_category.DisplayName', cellRenderer: this.badgeRenderer },
    { headerName: 'Action', cellRenderer: this.actionRenderer }
  ];
}
defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true
};
onCellClicked(event: any) {
  if (event.colDef.field === 'action') {
    if (event.event.target.classList.contains('fa-edit')) {
      this.editResource(event.data);
    } else if (event.event.target.classList.contains('fa-trash')) {
      this.deleteResource(event.data.name);
    }
  }
}
// Custom renderers
boldRenderer(params: any) {
  return params.value ? `<strong>${params.value}</strong>` : '-';
}

defaultRenderer(params: any) {
  return params.value ? params.value : '-';
}

badgeRenderer(params: any) {
  return `<span class="badge bg-success">${params.value ? params.value : '-'}</span>`;
}
actionRenderer(params: any) {
  return `
    <span class="icon me-2" onclick="editResource(${params.data.id})">
      <i class="fas fa-edit"></i>
    </span>
    <span class="icon" onclick="deleteResource('${params.data.name}')">
      <i class="fas fa-trash"></i>
    </span>
  `;
}
  closeModal(): void {
    this.close.emit(); // Emit close event
  }

  onSelectResource() {
    console.log('Selected Resource:', this.selectedResource);
    // Handle resource selection
}

onClearResource() {
    this.selectedResource = null;
}
onClearCategory() {
  this.selectedCategory = null;
}
onSelectCategory() {
    console.log('Selected Category:', this.selectedCategory);
    // Handle category selection
}
onAddNewResource(name: string) {
  const newId = this.resources.length + 1;
  this.resourcestype.push({ id: newId, name, description: 'New Description', category: 'New Category' });
  this.selectedResource = newId;
}

// Add new category
onAddNewCategory(name: string) {
  const newId = this.categories.length + 1;
  this.categories.push({ id: newId, DisplayName: name });
  this.selectedCategory = newId;
}

editResource(resource: any) {
  console.log('Edit resource:', resource);
  // Implement logic to edit the resource
}

// Delete resource method
deleteResource(resourceId: string) {
  console.log('Delete resource with ID:', resourceId);
  // Implement logic to delete the resource
  // this.resources = this.resources.filter(r => r.id !== resourceId);
}
getAllAgentList(){
  this.manageAgentService.getAllcatagory().subscribe((res:any)=>{
   this.categoryList=res?.data;
  
  })
  
}
getResourcesList(){
  this.manageAgentService.getAllResources().subscribe((res:any)=>{
   this.resourcesTypeList=res?.data;
   console.log("resouces type ",this.resourcesTypeList)
  
  })
  
}
submitForm() {
  if (this.form.valid) {
    const formData = {
      selectedResource: this.form.value.resourceSelect,
      resourceDescription: this.form.value.resourceDescription,
      selectedCategory: this.form.value.categorySelect,
      resourceName: this.form.value.resourceName,
      navIconClass: this.form.value.navIconClass,
      urlRoute: this.form.value.urlRoute
    };
    this.manageAgentService.createNewRes(formData).subscribe((res:any)=>{
    })
    console.log('Form Data:', formData);
  } else {
    this.form.markAllAsTouched(); 
  }

}
toggleExtraInputs() {
  this.showExtraInputs = !this.showExtraInputs;
}
}
