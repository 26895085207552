import { Component } from '@angular/core';
import { CommonService } from './shared/services/common.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent {

  constructor(public commonService: CommonService) { 

    const location =  window.location.href;
    const urlArray = location.split(':');
    
 /*    if(urlArray[0] === 'http'){
      window.location.href = `https:${urlArray[1]}`;
    } */
  }
}
