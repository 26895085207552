<div class="customizer-contain" [ngClass]="{ open: moreInfo == true }" [ngStyle]="{ 'width': popup1Width }">
    <div class="customizer-contain">
        <div class="tab-content" id="c-pills-tabContentAgent">
            <div class="customizer-header sticky-top bg-white py-3 d-flex justify-content-between align-items-center border-bottom">
                <h5 class="mb-0">
                    Manage <i class="fa fa-question-circle cursor-pointer ms-2" style="font-size: 17px;"></i>
                </h5>
                <i class="icofont-close icon-close cursor-pointer" (click)="closeModal()"></i>
            </div>
            <div class="card ">
                <div class="col-md-12 text-end mt-3">
                    <button class="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Create 
                    </button>
            </div>
            <form [formGroup]="form" (ngSubmit)="submitForm()">
                <div class="collapse mt-3" id="collapseExample">
                  <div class="card card-body bg-light mb-0">
                    <div class="row align-items-center justify-content-end">
                      <!-- Resource Dropdown -->
                      <div class="col-md-4 mb-3">
                        <label for="resourceSelect">Resource Code</label>
                        <ng-select
                          id="resourceSelect"
                          formControlName="resourceSelect"
                          [placeholder]="'Select or add a Resource'"
                          (change)="onSelectResource()"
                          (clear)="onClearResource()"
                          [allowClear]="true"
                          [addTag]="true"
                          (add)="onAddNewResource($event)"
                          [ngClass]="{'is-invalid': form.get('resourceSelect').invalid && form.get('resourceSelect').touched}">
                          <ng-option *ngFor="let resource of resourcesTypeList" [value]="resource.ResourceCategoryID">
                            {{ resource.DisplayName }}
                          </ng-option>
                          <ng-option [value]="-1" disabled>
                            + Add new resource...
                          </ng-option>
                        </ng-select>
                        <div *ngIf="form.get('resourceSelect').invalid && form.get('resourceSelect').touched" class="invalid-feedback">
                          Resource Code is required.
                        </div>
                      </div>
              
                      <!-- Description Input -->
                      <div class="col-md-4 mb-3">
                        <label for="resourceDescription">Description</label>
                        <input
                          type="text"
                          class="form-control"
                          id="resourceDescription"
                          formControlName="resourceDescription"
                          placeholder="Description..."
                          [ngClass]="{'is-invalid': form.get('resourceDescription').invalid && form.get('resourceDescription').touched}">
                        <div *ngIf="form.get('resourceDescription').invalid && form.get('resourceDescription').touched" class="invalid-feedback">
                          Description is required.
                        </div>
                      </div>
              
                      <!-- Category Dropdown -->
                      <div class="col-md-4 mb-3">
                        <label for="categorySelect">Resource Type</label>
                        <ng-select
                          id="categorySelect"
                          formControlName="categorySelect"
                          [placeholder]="'Select or add a category'"
                          (change)="onSelectCategory()"
                          (clear)="onClearCategory()"
                          [allowClear]="true"
                          [addTag]="true"
                          (add)="onAddNewCategory($event)"
                          [ngClass]="{'is-invalid': form.get('categorySelect').invalid && form.get('categorySelect').touched}">
                          <ng-option *ngFor="let category of categories" [value]="category.id">
                            {{ category.DisplayName }}
                          </ng-option>
                          <ng-option [value]="-1" [ngClass]="'add-category-option'" disabled>
                            + Add new category...
                          </ng-option>
                        </ng-select>
                        <div *ngIf="form.get('categorySelect').invalid && form.get('categorySelect').touched" class="invalid-feedback">
                          Resource Type is required.
                        </div>
                      </div>
                    </div>
              
                    <div class="col-md-12 span-head">
                      <span class="text-info cursor-pointer" style="font-size: 15px;" (click)="toggleExtraInputs()">
                        More Optional Fields <span *ngIf="showExtraInputs">-</span><span *ngIf="!showExtraInputs">+</span>
                      </span>
                    </div>
              
                    <!-- Additional Inputs Section -->
                    <div *ngIf="showExtraInputs" class="row mt-3" id="collapseExample2">
                      <div class="col-md-4 mb-3">
                        <label for="resourceName">Resource Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="resourceName"
                          formControlName="resourceName"
                          placeholder="Enter resource name">
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="navIconClass">Nav Icon Class</label>
                        <input
                          type="text"
                          class="form-control"
                          id="navIconClass"
                          formControlName="navIconClass"
                          placeholder="Enter nav icon class">
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="urlRoute">URL Route</label>
                        <input
                          type="text"
                          class="form-control"
                          id="urlRoute"
                          formControlName="urlRoute"
                          placeholder="Enter URL route">
                      </div>
                    </div>
              
                    <!-- Submit Button -->
                    <div class="col-md-12 text-end mt-3 px-0">
                      <button class="btn btn-outline-info" style="font-size: 15px;" type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
              
            <div class="ag-theme-alpine table-responsive mt-2" style="height: 400px;">
                <ag-grid-angular
                  style="width: 100%; height: 100%;"
                  class="ag-theme-alpine"
                  [rowData]="resourcesTypeList"
                  [columnDefs]="columnDefs"
                  [defaultColDef]="defaultColDef"
                  (cellClicked)="onCellClicked($event)"
                >
                </ag-grid-angular>
              </div>
              
            
            </div>
        </div>
    </div>
</div>

