import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { CustomerProfileService } from './customer-profile.service';
import { HttpService } from './httpService.service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  api: string = environment.BASE_API;
  private unsubscriber: Subject<any> = new Subject();
  public searchData: BehaviorSubject<any> = new BehaviorSubject({});
  public searchCount: BehaviorSubject<any> = new BehaviorSubject({});
  @LocalStorage()
  recentCustomerSearched
  public advanceSearchedData: BehaviorSubject<any> = new BehaviorSubject("");
  public advanceSearchedPayloadData: BehaviorSubject<any> = new BehaviorSubject("");
  public advanceSearchOrderData: BehaviorSubject<any> = new BehaviorSubject("");
  constructor(
    private http: HttpService,
    public customerProfileService: CustomerProfileService,
    private localStorage: LocalStorageService
  ) { }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  public setSearchData(value: any): void {
    this.searchData.next(value);
  }

  public setSearchCount(value: any): void {
    this.searchCount.next(value);
  }

  public setAdvanceSearchedData(value: any): void {
    this.advanceSearchedData.next(value);
  }
  public setAdvanceSearchedPayloadData(value: any): void {
    this.advanceSearchedPayloadData.next(value);
  }

  searchCustomer(body) {
    try {
      return this.http.get('api/Customer/search', body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getRecentCustomerSearchedData(): any[] {
    return this.recentCustomerSearched || [];
  }

  addToCustomerSearchedData(item: any) {
    let recentData = this.recentCustomerSearched || [];

    if (recentData.length > 0) {
      recentData.unshift(item);
      recentData = recentData.slice(0, 10); // Keep only 10 most recent items
    }
    else {
      recentData.push(item);
    }

    this.localStorage.store('recentCustomerSearched', recentData);
  }

  getAppSearchCount(body){
    try {
      let data ={
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
        search: body
      }
      return this.http.get('api/Customer/SearchCount', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  getOrderType(){
    try {
      let data ={
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
      }
      return this.http.get('api/orders/orderTypes', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAccountType(){
    try {
      let data ={
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
      }
      return this.http.get('api/orders/getPlanTypes', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCustomerStatus(){
    try {
      let data ={
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
        CustomerId: this.http.getCustomerId()?.CustomerId
      }
      return this.http.get('api/Customer/GetCustomerStatus', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  

}
