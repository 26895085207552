<!-- <app-breadcrumb [title]="'User'" [items]="['Apps', 'User']" [active_item]="'Edit Profile'"></app-breadcrumb> -->
<div class="container-fluid">
  <div class="edit-profile">
    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0" (click)="back()" style="font-size: 20px; cursor: pointer;"> <i class="fa-solid fa-arrow-left" ></i> My Profile</h4>
            <div class="card-options">
              <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="myProfile">
              <div class="row mb-2">
                <div class="col-auto"><img class="img-70 rounded-circle" alt="" src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg" /></div>
                <div class="col">
                  <h3 class="mb-1">MARK JECNO</h3>
                  <p class="mb-4">DESIGNER</p>
                </div>
              </div>
              <div class="mb-3">
                <h6 class="form-label">Bio</h6>
                <textarea class="form-control" rows="5" formControlName="bio"></textarea>
              </div>
              <div class="mb-3">
                <label class="form-label">Email-Address</label>
                <input class="form-control" placeholder="your-email@domain.com" formControlName="email" />
              </div>
              <div class="mb-3">
                <label class="form-label">Password</label>
                <input class="form-control" type="password" value="password" formControlName="password" />
              </div>
              <div class="mb-3">
                <label class="form-label">Website</label>
                <input class="form-control" placeholder="http://Uplor.com" formControlName="website" />
              </div>
              <div class="form-footer">
                <button class="btn btn-primary d-block w-100 me-1">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <form class="card" [formGroup]="editProfile">
          <div class="card-header">
            <h4 class="card-title mb-0">Edit Profile</h4>
            <div class="card-options">
              <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-5">
                <div class="mb-3">
                  <label class="form-label">Company</label>
                  <input class="form-control" type="text" placeholder="Company" formControlName="company" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="mb-3">
                  <label class="form-label">Username</label>
                  <input class="form-control" type="text" placeholder="Username" formControlName="userName" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">Email address</label>
                  <input class="form-control" type="email" placeholder="Email" formControlName="email" />
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input class="form-control" type="text" placeholder="First Name" formControlName="firstName" onlyAlphabets />
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input class="form-control" type="text" placeholder="Last Name" formControlName="lastName" onlyAlphabets />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Address</label>
                  <input class="form-control" type="text" placeholder="Home Address" formControlName="address" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">City</label>
                  <input class="form-control" type="text" placeholder="City" formControlName="city" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="mb-3">
                  <label class="form-label">Postal Code</label>
                  <input class="form-control" type="text" placeholder="ZIP Code" formControlName="zipCode" onlyNumbers />
                </div>
              </div>
              <div class="col-md-5">
                <div class="mb-3">
                  <label class="form-label">Country</label>
                  <select class="form-control">
                    <option disabled selected>--Select--</option>
                    <option value="1">Germany</option>
                    <option value="2">Canada</option>
                    <option value="3">Usa</option>
                    <option value="4">Aus</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3 mb-0">
                  <label class="form-label">About Me</label>
                  <textarea class="form-control" rows="5" placeholder="Enter About your description" formControlName="about"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-end">
            <button class="btn btn-primary" type="submit">Update Profile</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
