<div  class="d-flex justify-content-between">
  <p (click)="toggleMoreInfAgent();moreInfoSelectedTab('Line Information')"><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{manageAgentDetails?.DisplayName}}</p>
  <p><i class="fa-regular fa-circle-question hover-effect"
    [attr.title]="manageAgentDetails?.Description" 
    data-bs-toggle="popover" 
    data-bs-trigger="hover" 
    data-bs-class="custom-popover"
    data-bs-placement="top"
></i></p>
</div>

 <div class="customizer-contain" [ngClass]="{ open: moreInfoAgent == true }" [ngStyle]="{ 'width': popup1Width }">
  <div class="tab-content" id="c-pills-tabContentAgent">
      <div class="customizer-header">
          <h5 class="mb-0">Manage Agent Informations</h5>
          <i class="icofont-close icon-close" (click)="toggleMoreInfAgent()"></i>
      </div>

      <div class="customizer-header">
        <div class="row">
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Search">
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary" (click)="toggleMoreInfAgent1();moreInfoSelectedTab1('Line Information')">+ Add</button>
          </div>
        </div>
      </div>

      <div class="customizer-body custom-scrollbar">
          <div class="tab-pane fade" [ngClass]="{ 'active show': moreInfoAgent }" id="c-pills-home" role="tabpanel"
              aria-labelledby="c-pills-home-tab">
              <div class="notes-panel" [ngClass]="{'open-notes': true}">
                  <div class="wrapper d-flex bg-white">
                      <div class="right-panel w-100">
                          <div class="container-fluid">
                              <div class="row">
                                  <div class="col-md-12 mx-auto">
                                      <div class="table-responsive marginB2">
                                          <table class="table">
                                              <thead>
                                                  <tr>
                                                      <th>
                                                        <div class="form-check">
                                                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                                        </div>                                                     
                                                       </th>
                                                      <th scope="col" sortable="fieldName">Group Name</th>
                                                      <th scope="col" sortable="description">Employee Name</th>
                                                      <th scope="col" sortable="example">Retailer Name</th>
                                                      <th scope="col" sortable="example">Distributer Name</th>
                                                      <th scope="col" sortable="example">Master Agent</th>
                                                      <th scope="col" sortable="example">Login Id</th>
                                                      <th scope="col" sortable="example">Email Id</th>
                                                      <th scope="col" sortable="example">Address</th>
                                                      <th scope="col" sortable="example">City</th>
                                                      <th scope="col" sortable="example">State</th>
                                                      <th scope="col" sortable="example">Zip</th>
                                                      <th scope="col" sortable="example">status</th>
                                                      <th scope="col" sortable="example">Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let item of agentList">
                                                    <td>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                                      </div>
                                                    </td>
                                                      <td scope="row">
                                                          <ngb-highlight [result]="item.GroupID"
                                                              [term]="'GroupID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.user.name"
                                                              [term]="'user.name'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.RetailerID"
                                                              [term]="'RetailerID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.DistributorID"
                                                              [term]="'DistributorID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.MasterAgentID"
                                                              [term]="'MasterAgentID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.UserID"
                                                              [term]="'UserID'"></ngb-highlight>
                                                      </td>

                                                      <td>
                                                          <ngb-highlight [result]="item.user.email"
                                                              [term]="'user.email'"></ngb-highlight>
                                                      </td>

                                                      <td>
                                                        <ngb-highlight [result]="item.AddressLine1"
                                                            [term]="'AddressLine1'"></ngb-highlight>
                                                    </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.CityID"
                                                              [term]="'CityID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.StateID"
                                                              [term]="'StateID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.ZipID"
                                                              [term]="'ZipID'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.user.status_id"
                                                              [term]="'user.status_id'"></ngb-highlight>
                                                      </td>
                                                      <td class="d-flex">
                                                        <p class="mx-2" (click)="toggleMoreInfAgent1();moreInfoSelectedTab1('Line Information');editAgentData(item.id)"><i class="fa-solid fa-pen-to-square"></i></p>
                                                        <p class="mx-2"><i class="fa-solid fa-trash"></i></p>
                                                        <p  [routerLink]= "['/profile',item.UserID]" class="mx-2" (click)="loadAgents(true)"><i class="fa-solid fa-eye" (loaderCHanged)="handleActivationChanged(true)"></i></p>
                                                        <p  (click)="navigateForPermission(item?.id)" class="mx-2"><i class="fa-solid fa-user-lock"></i></p>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                        <div class="pull-right p-20 d-flex">
                                        <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                                            (change)="onChangeNumberOfPage()">
                                            <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                                        </select>
                                        <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems" [(page)]="currentPage" (pageChange)="pageChange($event)"
                                            [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                                                <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
                                        </div>

                                      </div>
                                      <br><br>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>




<!-- ////////////////////////////////////// -->

<div class="customizer-contain" [ngClass]="{ open: moreInfoAgent1 == true }" [ngStyle]="{ 'width': popup2Width }">
  <div class="tab-content" id="c-pills-tabContentAgent">

      <div class="customizer-header">
          <h5 class="mb-0" *ngIf="!editId">Add roles and data restrictions </h5>
          <h5 class="mb-0" *ngIf="editId">Edit roles and data restrictions </h5>
          <i class="icofont-close icon-close" (click)="toggleMoreInfAgent1()"></i>
      </div>

      <div class="customizer-body custom-scrollbar">
          <div class="tab-pane fade" [ngClass]="{ 'active show': moreInfoAgent1}" id="c-pills-home" role="tabpanel"
              aria-labelledby="c-pills-home-tab">
              <div class="notes-panel" [ngClass]="{'open-notes': true}">
                  <div class="wrapper d-flex bg-white">
                      <div class="right-panel w-100">
                          <div class="container-fluid">
                            <form [formGroup]="addAgentForm">
                              <div class="row d-flex justify-content-center">
                                 <div class="col-md-10">
                                     <div class="card">
                                        <div class="card-body">
                                          <div class="row">
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Group Name</label>
                                                <input class="form-control" placeholder={{this.singleAgentData[0]?.Group}} type="text" formControlName="groupName">
                                               </div>
                                              <!-- 
                                               <div class="text-danger" *ngIf="addAgentForm.get('groupName').hasError('required') && addAgentForm.get('groupName').touched">
                                                Group Name is required.
                                              </div> -->
                                            </div>
                                          
                                            <div class="col-md-6" >
                                              <div class="form-group">
                                                <label>Employee name</label>
                                                <input  class="text-danger" class="form-control"placeholder={{this.singleAgentData[0]?.user?.name}} type="text" formControlName="employeeName">
                                               </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('employeeName').hasError('required') && addAgentForm.get('employeeName').touched">
                                                Employee Name is required.
                                              </div> -->

                                            </div>

                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Master Agent</label>
                                                <ng-select formControlName="masterAgent" (change)="onMasterAgentSelected($event)" >
                                                  <ng-option *ngFor="let item of masterAgentList" [value]="item">{{item.MasterAgentName}}</ng-option>
                                                  </ng-select>
                                               </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('masterAgent').hasError('required') && addAgentForm.get('masterAgent').touched">
                                                Master Agent is required.
                                              </div> -->

                                            </div>


                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Distributer name</label>
                                                <ng-select formControlName="distributerName">
                                                  <ng-option *ngFor="let item of distributorList" [value]="item">{{item.DistributerName}}</ng-option>
                                                  </ng-select>
                                               </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('distributerName').hasError('required') && addAgentForm.get('distributerName').touched">
                                                Distributer Name is required.
                                              </div> -->

                                            </div>



                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Retailer name</label>
                                                <ng-select formControlName="retailerName">
                                                  <ng-option value="">firstName</ng-option>
                                                  <ng-option value="">firstName</ng-option>
                                                  <ng-option value="">firstName</ng-option>
                                                  <ng-option value="">firstName</ng-option>
                                                  <ng-option value="">firstName</ng-option>
                                                  </ng-select>
                                               </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('retailerName').hasError('required') && addAgentForm.get('retailerName').touched">
                                                Retailer Name is required.
                                              </div> -->

                                            </div>
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>State</label>
                                                <ng-select formControlName="state" >
                                                  <ng-option  *ngFor="let state of stateList" [value]="state" >{{state}}</ng-option>
                                                 
                                                  </ng-select>
                                               </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('state').hasError('required') && addAgentForm.get('state').touched">
                                                State is required.
                                              </div> -->

                                            </div>
                                           
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>City</label>
                                                <ng-select formControlName="city">
                                                  <ng-option  *ngFor="let city of citiesList" [value]="city" >{{city}}</ng-option>

                                                </ng-select>
                                              </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('city').hasError('required') && addAgentForm.get('city').touched">
                                                City is required.
                                              </div> -->

                                            </div>
                                           
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Zip</label>
                                                <!-- <input  class="text-danger" class="form-control" type="text" placeholder="Enter last name" formControlName="zip"> -->
                                                <ng-select formControlName="zip" >
                                                  <ng-option  *ngFor="let zip of zipCodeDatas" [value]="zip" >{{zip}}</ng-option>
                                                 
                                                  </ng-select>
                                               </div>

                                               <!-- <div class="text-danger" *ngIf="addAgentForm.get('zip').hasError('required') && addAgentForm.get('zip').touched">
                                                Zip is required.
                                              </div> -->

                                            </div>
                                          </div>
                                          <div class="row mt-2">
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Email Address</label>
                                                <input  class="text-danger" class="form-control" type="text" placeholder="Enter email address" formControlName="email">
                                               </div>
<!-- 
                                               <div class="text-danger" *ngIf="addAgentForm.get('email').hasError('required') && addAgentForm.get('email').touched">
                                                Email is required.
                                              </div>
                                              <div  class="text-danger" *ngIf="addAgentForm.get('email').hasError('email') && addAgentForm.get('email').touched">
                                                Please enter a valid email address.
                                              </div> -->
                                            </div>

                                            <!-- <div class="col-md-6">
                                              <div class="form-group">
                                                <label>User Name</label>
                                                <input class="form-control" type="text" placeholder="Enter user name" formControlName="userName">
                                               </div>
                                               <div  class="text-danger" *ngIf="addAgentForm?.get('userName')?.hasError('required') && addAgentForm?.get('userName')?.touched">
                                                User Name is required.
                                               </div>
                                               <div [hidden]="check"
                                               [ngClass]="{'green-text': checkDuplicateUser ==='User not registered', 'red-text': checkDuplicateUser === 'User is already registered'}">{{checkDuplicateUser}}</div>
                                            </div> -->
                                         

                                            <div class="col-md-6">
                                              <label for="exampleSelect" class="form-label">Status</label>
                                              <select class="form-select" id="exampleSelect" formControlName="user_type">
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>
                                              </select>

                                              <!-- <div class="text-danger" *ngIf="addAgentForm.get('user_type').hasError('required') && addAgentForm.get('user_type').touched">
                                                User Type is required.
                                              </div> -->

                                        </div>

                                          </div>
                                          <!-- <div class="row mt-3">
                                            <div class="col-md-10">
                                              <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="exampleCheckbox" formControlName="newUserEmail">&nbsp;&nbsp;
                                                <div  class="text-danger" *ngIf="addAgentForm.get('newUserEmail').hasError('required') && addAgentForm.get('newUserEmail').touched">
                                                  Notify new users by email
                                                  is required.
                                                 </div>
                                                <label class="form-check-label" for="exampleCheckbox" >
                                                  Notify new users by email
                                                </label>
                                              </div>
                                            </div>
                                          </div> -->
                                        </div>
                                      </div>   
                                 </div>
                              </div>

                             <div class="row d-flex justify-content-center marginB">
                              <div class="col-md-10">
                              <button  *ngIf="!editId" class="btn btn-primary" (click)="submitAgent()">Submit</button>
                              <button  *ngIf="editId" class="btn btn-primary" (click)="submitAgent()">Update</button>
                              </div>
                             </div>
                             </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<ng-container *ngIf="loadAgent">
  <app-loader [isChildLoader]="true"></app-loader>
</ng-container>