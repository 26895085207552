import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UserSettingsDashboard } from './user_settings/user_settings_dashboard/user_settings_dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { SearchResultPageComponent } from './search-result-page/search-result-page.component';
import { ManageUserComponent } from './user_settings/user_settings_dashboard/manage-user/manage-user.component';
import { ManageAgentComponent } from './user_settings/user_settings_dashboard/manage-agent/manage-agent.component';
import { GroupUserComponent } from './user_settings/user_settings_dashboard/group-user/group-user.component';
import { ManagePermissionComponent } from './user_settings/user_settings_dashboard/manage-permissions/manage-permissions.component';
import { MessageService } from 'primeng/api';
import { AddMemberGroupComponent } from './add-member-group/add-member-group.component';
import { ManageGroupPermissionComponent } from './user_settings/user_settings_dashboard/manage-group-permissions/manage-group-permissions.component';
import { ManageCombosComponent } from './user_settings/user_settings_dashboard/manage-combos/manage-combos.component';
import { ManageNoteTypesComponent } from './user_settings/user_settings_dashboard/manage-note-types/manage-note-types.component';
import { ManageComboItemsComponent } from './user_settings/user_settings_dashboard/manage-combo-items/manage-combo-items.component';
import { ManageMakeModelsComponent } from './user_settings/user_settings_dashboard/manage-make-models/manage-make-models.component';
import { ImageModalComponent } from './user_settings/user_settings_dashboard/manage-combo-items/image-modal/image-modal.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AgGridModule } from 'ag-grid-angular';
import { LoaderInterceptor } from './shared/services/loader.interceptor';
import { ManageResourcesComponent } from './user_settings/user_settings_dashboard/manage-resources/manage-resources.component';
import { ResourceTypeModalComponent } from './user_settings/user_settings_dashboard/resource-type-modal/resource-type-modal.component';
import { ManageCategoryComponent } from './user_settings/user_settings_dashboard/manage-category/manage-category.component';
import { NewManageUserComponent } from './user_settings/user_settings_dashboard/new-manage-user/new-manage-user.component';
import { EditManageUsersComponent } from './user_settings/user_settings_dashboard/edit-manage-users/edit-manage-users.component';
import { NewProfileComponent } from './user_settings/user_settings_dashboard/new-profile/new-profile.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    // OrderDashboardComponent,
    // StatusCardComponent,
    //TabsCardComponent,
    // FiltersComponent,
    // OrderTablesComponent,
   ManageUserComponent,
   ManageAgentComponent,
   ManagePermissionComponent,
   ManageGroupPermissionComponent,
   GroupUserComponent,
    UserSettingsDashboard,
    LoginComponent,
    ProfileComponent,
    AddMemberGroupComponent,
    SearchResultPageComponent,
    ManageCombosComponent,
    ManageNoteTypesComponent,
    ManageComboItemsComponent,
    ManageMakeModelsComponent,
    ImageModalComponent,
    ManageResourcesComponent,
    ResourceTypeModalComponent,
    ManageCategoryComponent,
    NewManageUserComponent,
    EditManageUsersComponent,
    NewProfileComponent

  ],
  imports: [
    AgGridModule,
    CommonModule,
    BrowserModule,
    CdkStepperModule,
    FormsModule,
    NgxWebstorageModule.forRoot(
      { prefix: 'tibss', separator: '.', caseSensitive: true }
    ),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    RecaptchaModule,
    NgbPaginationModule,
    RecaptchaFormsModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule
  ],

  providers: [ManageCategoryComponent,{ provide: LocationStrategy, useClass: HashLocationStrategy }, MessageService, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: TokenInterceptor,
  //   multi: true
  // }
],
schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})

export class AppModule { }
