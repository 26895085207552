import { Injectable } from '@angular/core';
import { HttpService } from './httpService.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerSettingService {

    constructor(private http:HttpService) { }

  async getResouceDetails(){
    try {
      let data={
        resource_Category:"SYSTEM_SETTINGS"
      }
      return this.http.get('api/system/resourcePermissionswithChilds',data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
}
