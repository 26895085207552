import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '../../services/user-agent.service';
import { firstValueFrom } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
@Component({
  selector: 'app-edit-manage-users',
  templateUrl: './edit-manage-users.component.html',
  styleUrls: ['./edit-manage-users.component.scss']
})
export class EditManageUsersComponent {
  addAgentForm: FormGroup;
  editId: boolean = false;
  show: boolean = false
  checkDuplicateUser: any;
  allRoleList: any
  applicationInfo: any
  public check = true;
  id:any
  @LocalStorage()
  private userDetails;
  constructor(private fb: FormBuilder, private userAgentService: UserAgentService,private router:Router,private route: ActivatedRoute,private commonService:CommonService) { }

  ngOnInit(): void {
    history.state.user
    console.log("his",history.state.user)
    this.id = history.state.user.id
 
    this.getApplications()
    this.addAgentForm = this.fb.group({
      fullName: ["", Validators.required],
      email: [{ value: '' }, [Validators.required, Validators.email]],
      userName: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9]{8,}$')]],
      password: ["", [Validators.required, Validators.pattern(`^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+={};':",./<>?]{8,}$`)]],
      user_type: ["", Validators.required],
      role: ['', Validators.required],
      application: ['', Validators.required]
 
    })
    this.getRoleList();
    this.getUser()
    if (history.state.user) {
      this.editId = true
      this.addAgentForm.patchValue({
        fullName: history.state.user.name,
        email: history.state.user.email,
        userName: history.state.user.userName,
        password: history.state.user.password,
        user_type: history.state.user.status?.id,
        role: history.state.user.usergroup[0].id,
        application: history.state.user.aqsa_tel_app_users.length == 2 ? 3:history.state.user.aqsa_tel_app_users[0].id
      });
    }
  }
  userNameVerify(event: any) {
    const userNameValue = event?.target?.value;
    this.userAgentService?.userNameVerify(userNameValue).subscribe((res: any) => {
      this.checkDuplicateUser = res.message
      if (this.checkDuplicateUser == 'User not registered') {
        this.check = false
      } else if (this.checkDuplicateUser == 'User is already registered'
      ) {
        this.check = false;
      } else {
        this.check = true;
      }
    }, (error) => {
      this.check = true
    })
  }
  showPassword() {
    this.show = !this.show
  }
  getRoleList() {
    this.userAgentService.getRoleList().subscribe((res: any) => {
      this.allRoleList = res.data;
      this.allRoleList.push({ id: 11, Code: 'None', Description: 'No Roles', GroupEmail: "", GroupOwner: "", GroupTypeID: "", IsActive: "", IsDeleted: "", Name: "None", created_at: "" })
      console.log('role list: ', this.allRoleList)
    })
  }
  async getApplications() {
    try {
      const data: any = await firstValueFrom(this.userAgentService.getApplication());
      if (data?.Status) {
        this.applicationInfo = data?.data;
        this.applicationInfo.push({
          "id": 3,
          "Name": "All",
          "IsActive": 1,
          "IsDeleted": 0,
          "CreatedDate": "2024-08-05 14:43:53"
        },)
        console.log('application info: ', this.applicationInfo);
      } else {
        this.applicationInfo = [];
      }
    } catch (error) {
      console.error('Getting error in accessing application information', error);
    }
  }
  submitAgent() {
    if (this.addAgentForm.invalid && !this.editId) {
      this.addAgentForm.markAllAsTouched();
      console.log('Invalid')
      return
    } else {
      let roleValue = this.addAgentForm.value.role;
      let groupId = roleValue && !isNaN(Number(roleValue)) ? Number(roleValue) : null;
      let application = this.addAgentForm.value.application
      let obj = {}
      if (application == 3) {
        obj = {
          name: this.addAgentForm.value.fullName,
          email: this.addAgentForm.value.email,
          userName: this.addAgentForm.value.userName,
          password: this.addAgentForm.value.password,
          UserTypeID: 1,
          status_id: this.addAgentForm.value.user_type,
          userID:this.id,
          companyId: "UNITYCRM0021",
          groupId: groupId,
          aqsaTelApps: [1, 2]


        }
      } else {
        obj = {
          name: this.addAgentForm.value.fullName,
          email: this.addAgentForm.value.email,
          userName: this.addAgentForm.value.userName,
          password: this.addAgentForm.value.password,
          UserTypeID: 1,
          status_id: this.addAgentForm.value.user_type,
          userID: this.id,
          companyId: "UNITYCRM0021",
          groupId: groupId,
          aqsaTelApps: [this.addAgentForm.value.application]


        }
      }

      if (!this.editId) {

        this.userAgentService.saveUserData(obj).subscribe((res: any) => {
          // this.openSnackBar('User Created Successfully..',3000)
          this.commonService.showToast("success", "Success", 'User Created Successfully');
          // this.getUserList()
        })
      } else {

        const response: any = this.userAgentService.updateUserData(obj, '');
        response.subscribe((res: any) => {
          this.commonService.showToast("success", "Success", 'User Updated Successfully..');
          // this.getUserList()

        })

      }



    }

  }
  getUser() {
    return this.userDetails;
  }
  back(){
    this.router.navigateByUrl(`/manage-user`)
  }
}
