import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent, Observable } from "rxjs";
import { HttpService } from "./httpService.service";

import { LocalStorage, LocalStorageService } from "ngx-webstorage";
import { CommonService } from "./common.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

declare var require
const Swal = require('sweetalert2')


@Injectable({
  providedIn: "root",
})
export class CustomerProfileService implements OnDestroy {



  private unsubscriber: Subject<any> = new Subject();

  public isEditProfileQuickbarOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public CustomerDataUpdated: Subject<any> = new Subject();

  public minimalProfileData = null
  
  public isSubscriptionEmitter = new EventEmitter<boolean>();
  public isSchedulededPlanEmitter = new EventEmitter<boolean>();
  public subascriptionData: any;


  isShowAlert: boolean = false;
  @LocalStorage()
  private CustomerId;

  @LocalStorage()
  private userDetails;

  @LocalStorage()
  private mingleUserDetail;

  customerEmail: string = '';
  customerName: string = ''

  customerIdKey: string = "CustomerId";

  constructor(
    private http: HttpService,
    private localStorage: LocalStorageService,
    public common: CommonService,
    public httpClient:HttpClient
  ) {

  }
  getAccountType(requestData: { companyId: string; userID: number; CustomerId: number; }) {
    return this.http.get('api/Customer/CustomerDeviceEligibility', requestData).toPromise();
  }
  removeImei(requestData: { companyId: string; userID: number; CustomerId: number; }) {
    return this.http.delete('api/Customer/CustomerDeviceRemove', requestData).toPromise();
  }
  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public setProfileQuicklink(value: boolean): void {
    this.isEditProfileQuickbarOpen.next(value);
  }

  public cancelSubscription(customerId: any) {
    try {
      let data = {
        "CustomerId": customerId
      }
      return this.http.post('api/Customer/cancelSubscription', data).toPromise()
    } catch (error) {
      this.common.loader = false
      throw Error(error)
    }
  }
  async getMinimalProfileData(SearchCustomerId?:any) {
    this.common.loader = true;
    try {
      const data = {
        CustomerId: SearchCustomerId ?? this.getCustomerId()
      };

      const res: any = await this.http.get('api/Customer/getMinimalProfileData', data, false).toPromise();
      if (res.Status) {
        this.minimalProfileData = res?.data;

        const isSubscription = res?.data?.subscription != null && res?.data?.subscription.length >= 1;
        this.isSubscriptionEmitter.emit(isSubscription);
        const schedulededPlan = res?.data?.schedulededPlan
        let isSchedulededPlan ;
        if (Array.isArray(schedulededPlan)) {
          isSchedulededPlan = false;
        } else if (typeof schedulededPlan === 'object' && schedulededPlan !== null) {
          isSchedulededPlan =  true;
        } 

        
        this.isSchedulededPlanEmitter.emit(isSchedulededPlan);
        this.localStorage.store("EnrollmentNo", this.minimalProfileData?.accountInfo?.enrollId?.value);
        sessionStorage.setItem("EnrollmentNo", this.minimalProfileData?.accountInfo?.enrollId?.value);

        this.showAlerts(res.data);
      } else {
        this.common.showToast("error", "Error", res?.message);
      }
    } catch (err) {
      console.error('Failed to get minimal profile data:', err);
      this.common.showToast("error", "Error", err?.message ?? "Some Error Occurred");
    } finally {
      this.common.loader = false;
      if(SearchCustomerId){
        localStorage.removeItem('SearchCustomerId');
      }
    }
  }

  async getEmail(): Promise<string> {
    try {
      this.common.loader = true
      const res: any = await this.http.get('api/Customer/getMinimalProfileData', null, false, true).toPromise();
      if (res?.Status) {
        this.customerEmail = res?.data?.personalInfo?.email?.value || ''
        return this.customerEmail
      }
      return ''
    } catch (error) {
      console.log(error)
      return ''
    }
  }
  async getCustomerName(): Promise<string> {
    try {
      this.common.loader = true
      const res: any = await this.http.get('api/Customer/getMinimalProfileData', null, false, true).toPromise();
      if (res?.Status) {
        this.customerName = res?.data?.personalInfo?.name?.value || ''
        return this.customerName
      }
      return ''
    } catch (error) {
      console.log(error)
      return ''
    }
  }
  subData() {
    let data = {
      "CustomerId": "2004745",
      "companyId": "UNITYCRM0021",
      "userID": "1"
    }
    return this.http.get('api/Customer/getMinimalProfileData', data, false, true).toPromise();
  }
  showAlerts(data) {
    if (this.isShowAlert) {
      return
    }
    if (data.alerts.length == 0) {
      return
    }
    this.isShowAlert = true;
    let timerInterval
    let html = ''
    for (let index = 0; index < data.alerts.length; index++) {
      const alert = data.alerts[index];
      html = html + `<div class="text-left">${alert.message}</div><div><hr></div>`
    }
    Swal.fire({
      title: 'Alerts!',
      html: html,
      timer: 5000,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector('strong')
            .textContent = Swal.getTimerLeft()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    })
  }


  getCustomerSubscription(customerid: any, single?: boolean) {
    try {
      this.common.loader = true
      let data: any = {
        companyId: "UNITYCRM0021",
        userID: "1",
        CustomerId: customerid, // optional
      };

      if (single) {
        data.single = "yes";
      }
      return this.http.get('api/Customer/Subscriptions', data, false, true).toPromise()
    } catch (err) {
      this.common.loader = false
      throw Error(err)
    }

  }
  getCustomerPaymentProfile(data:any){
    try {
      this.common.loader = true
      let params: any = {
        profileId:data?.profileId,
        paymentProfileId:data?.paymentProfileId
      };

      return this.http.get('api/subscription/getCustomerPaymentProfile', params, false, true).toPromise()
    } catch (err) {
      this.common.loader = false
      throw Error(err)
    }
  }

  getCustomerPersonalCardData() {
    try {
      return this.http.get('api/Customer/getCustomerPersonalInfo', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getLineInfoCardData() {
    try {
      let CustomerId = this.getCustomerId()
      return this.http.get('api/Customer/LineInfo/' + CustomerId).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getLineInfoSimType() {
    try {
      let data = {
        CustomerId: this.getCustomerId()
      }
      // let CustomerId = this.getCustomerId()
      return this.http.get('api/Customer/GetSimType', data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  getLineInfoMoreCardData() {
    try {
      let CustomerId = this.getCustomerId()
      return this.http.get('api/Customer/GetlineInfoMore/' + CustomerId).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCustomerInfoCardData() {
    try {
      return this.http.get('api/Customer/getCustomerInfo', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCustomerUsagesCardData() {
    try {
      return this.http.get('api/Customer/getCustomerUsages', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  generatePaymentLink(customerId:number){

    try {
      const params = new HttpParams()
      .set('companyId', 'UNITYCRM0021')
      .set('CustomerId', this.http.getCustomerId().toString())
      .set('userID', this.http.getUser().userID.toString());
      return this.httpClient.get<any>(`https://prepaid.unitywireless.com/generate-link/${customerId}`,{params, responseType: 'text' as 'json'})
    } catch (error) {
      throw Error(error)
    }
  }
  getSideMenuTypes() {
    try {
      // let url = "api/system/resource-permission-detail"
      let url = "api/system/resourcePermissionswithChilds"

      let body = {
        "userID": this.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "resource_Category[]": [
          "MENUS"
          // "SIDEBAR_MENU"
        ]
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getFinancialCardData() {
    try {
      return this.http.get('api/Customer/getFinancial-info', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getEligibilityCardData() {
    try {
      return this.http.get('api/Customer/getEligibilityInfo', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  //method for future requirement 
  getSubscriptionCardData() {
    try {
      return this.http.get('api/Customer/getMinimalProfileData', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getActivityInfo(data) {
    try {
      let body = {
        "type": "CustomerEvents",
        ...data
      }
      return this.http.get('api/Customer/getActivityInfo', body, false, true)?.toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateCustomerProfile(data) {
    try {
      return this.http.put('api/Customer/changeCustomerInfo', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
sendToEmail(data){
  try {
    // return this.http.post('api/Customer/UspsAddresses', data).toPromise()
    return this.http.post('api/Pre-paid/GetUserName', data).toPromise()
  } catch (err) {
    throw Error(err)
  }
}
  updateUspsAddresses(data) {
    try {
      // return this.http.post('api/Customer/UspsAddresses', data).toPromise()
      return this.http.post('api/Customer/ChangeAddress', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateMDN(data: any) {
    try {
      return this.http.post('api/Customer/MdnChange', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateSim(data) {
    try {
      return this.http.post('api/Customer/SimChange', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  generateQrCode(data) {
    try {
      return this.http.post('api/Customer/generateQRCode', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateImei(data) {
    try {
      return this.http.post('api/Customer/CustomerDeviceUpdate', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  simChangeNonElec(data) {
    try {
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.getUser()?.userID,
        ...data
      }
      console.log("Sim Change Non Electronics :-", body)
      return this.http.post('api/Customer/SimChangeNonElec', body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  setCustomerId(id) {
    this.localStorage.store(this.customerIdKey, id);
    sessionStorage.setItem(this.customerIdKey, id)
    setTimeout(() => {
      this.CustomerDataUpdated.next(true)
    }, 1000);
  }

  getCustomerId() {
    return sessionStorage.getItem('CustomerId') ?? this.CustomerId
  }



  getStatusOptions() {
    try {
      return this.http.get('api/Customer/GetCustomerStatus', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getExceptionCodeOptions() {
    try {
      return this.http.get('api/Customer/getExceptionCodes', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getDeEnrollCodeOptions() {
    try {
      return this.http.get('api/Customer/GetNladDeEnrollCodes', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getRejectionsOptions() {
    try {
      return this.http.get('api/Customer/GetRejections', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateStatus(data, typeCall) {
    let url = 'api/Customer/CustomerStatus';
    if (typeCall === 'non-electronic') {
      url = "api/Customer/ChangeStatusSystem";
    }
    try {
      return this.http.post(url, data).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }

  getPlanScheduler() {
    try {
      let url = "api/downgrade-jobs/getPlanScheduler"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.getUser()?.userID,
        "CustomerId": this.getCustomerId()
        //"status": "Failed" // its optional
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  dashboardGetActivity() {
    try {
      let url = "api/Customer/getActivityInfo"
      let body = {
        "reportType": "User",
        "companyId": "UNITYCRM0021",
        "userID": this.getUser()?.userID
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketStatsByAgent() {
    try {
      let url = "api/analytics/getTicketStatsByAgent"
      let body = {
        "companyId": "UNITYCRM0021",
        "LastDays": 15,
        "userID": this.getUser()?.userID
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCarouselUpdates() {
    try {
      let url = "api/get-carousel-updates"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getCustomerOtherInfo() {
    try {
      return this.http.get('api/Customer/getCustomerOtherInfo', null, false, true).toPromise()

    } catch (err) {
      throw Error(err)
    }
  }

  getNVUpdates(EnrollNumber) {
    try {
      let url = "api/acptransfer/getNVResponse"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "EnrollNumber": EnrollNumber
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  createExceptionWorksheet(data) {
    try {
      var transferException;
      var jsonObject = JSON.parse(data.Request);
      if (jsonObject.transferException) transferException = jsonObject.transferException;
      else transferException = jsonObject.transException;
      let url = "api/acptransfer/exceptionWorksheet"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "EnrollNumber": data.EnrollNumber,
        "ExceptionCode": transferException,
        "Date": data.ConsentDate,
      }
      return this.http.post(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  downloadEnrollmentPdf() {
    try {
      let url = `api/acptransfer/enrollment-pdf/${this.getCustomerId()}`
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getUser() {
    return localStorage.getItem('BaseUrl') === environment.AQSATEL_API ? this.userDetails : this.mingleUserDetail
  }

  getShipperStationData() {
    try {
      console.log('api icalled');
      let data={

      }
      let url = "api/shipping/getStores";
      return this.http.get('api/shipping/getStores',data);
    } catch (err) {
      throw Error(err)
    }
  }
}
