import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent, Observable } from "rxjs";
import { HttpService } from "./httpService.service";

import { LocalStorage, LocalStorageService } from "ngx-webstorage";
import { CommonService } from "./common.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/auth/services/auth.service";

declare var require;
const Swal = require("sweetalert2");

@Injectable({
  providedIn: "root",
})
export class CustomerProfileService implements OnDestroy {
  isPrePaidEvent = new BehaviorSubject<boolean>(false);
  isPrePaidEvent$ = this.isPrePaidEvent.asObservable();
  customerNameEvent = new BehaviorSubject<string>("");
  customerNameEvent$ = this.customerNameEvent.asObservable();
  private unsubscriber: Subject<any> = new Subject();
  public sideMenubarSub = new BehaviorSubject<any[]>([]);
 public sideMenubarSub2  = new BehaviorSubject<any[]>([]);
  executivePermission:any
  executivePermission2:any
  public isEditProfileQuickbarOpen: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  public CustomerDataUpdated: Subject<any> = new Subject();

  public minimalProfileData = null;

  public isSubscriptionEmitter = new EventEmitter<boolean>();
  public isSchedulededPlanEmitter = new EventEmitter<boolean>();
  public subascriptionData: any;

  isShowAlert: boolean = false;
  public userData: any;

  @LocalStorage()
  private CustomerId;

  @LocalStorage()
  private userDetails;

  @LocalStorage()
  private mingleUserDetail;

  customerEmail: string = "";
  customerName: string = "";

  customerIdKey: string = "CustomerId";

  constructor(
    private http: HttpService,
    private localStorage: LocalStorageService,
    public common: CommonService,
    public httpClient: HttpClient,
    private authService: AuthService
  ) {}
  getAccountType(requestData: {
    companyId: string;
    userID: number;
    CustomerId: number;
  }) {
    return this.http
      .get("api/Customer/CustomerDeviceEligibility", requestData)
      .toPromise();
  }
  removeImei(requestData: {
    companyId: string;
    userID: number;
    CustomerId: number;
  }) {
    return this.http
      .delete("api/Customer/CustomerDeviceRemove", requestData)
      .toPromise();
  }
  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public setProfileQuicklink(value: boolean): void {
    this.isEditProfileQuickbarOpen.next(value);
  }

  public cancelSubscription(customerId: any) {
    try {
      let data = {
        CustomerId: customerId,
      };
      return this.http
        .post("api/Customer/cancelSubscription", data)
        .toPromise();
    } catch (error) {
      this.common.loader = false;
      throw Error(error);
    }
  }
  async getMinimalProfileData(SearchCustomerId?: any, hideLoader?: boolean) {
    this.common.loader = true;
    try {
      const data = {
        CustomerId: SearchCustomerId ?? this.getCustomerId(),
      };
      // Call the HTTP get method with headers
      const res: any = await this.http
        .get(
          "api/Customer/getMinimalProfileData",
          data,
          false,
          false,
          hideLoader
        )
        .toPromise();
      if (res.Status) {
        this.minimalProfileData = res?.data;
        this.isPrePaidEvent.next(
          this.minimalProfileData?.personalInfo?.customerType?.value ==
            "Pre-Paid"
            ? false
            : true
        );
        const initials = this.extractInitials();
        this.customerNameEvent.next(initials);
        const isSubscription =
          res?.data?.subscription != null &&
          res?.data?.subscription.length >= 1;
        this.isSubscriptionEmitter.emit(isSubscription);
        const schedulededPlan = res?.data?.schedulededPlan;
        let isSchedulededPlan;
        if (Array.isArray(schedulededPlan)) {
          isSchedulededPlan = false;
        } else if (
          typeof schedulededPlan === "object" &&
          schedulededPlan !== null
        ) {
          isSchedulededPlan = true;
        }

        this.isSchedulededPlanEmitter.emit(isSchedulededPlan);
        this.localStorage.store(
          "EnrollmentNo",
          this.minimalProfileData?.accountInfo?.enrollId?.value
        );
        sessionStorage.setItem(
          "EnrollmentNo",
          this.minimalProfileData?.accountInfo?.enrollId?.value
        );

        this.showAlerts(res.data);
      } else {
        this.common.showToast("error", "Error", res?.message);
      }
    } catch (err) {
      console.error("Failed to get minimal profile data:", err);
      this.common.showToast(
        "error",
        "Error",
        err?.message ?? "Some Error Occurred"
      );
    } finally {
      this.common.loader = false;
      if (SearchCustomerId) {
        localStorage.removeItem("SearchCustomerId");
      }
    }
  }

  extractInitials(): string {
    const fullName = this.userDetails?.name || "";
    const nameParts = fullName.split(" ").filter((part) => part.trim() !== "");
    const firstName = nameParts[0] || "";
    const lastName = nameParts[nameParts.length - 1] || "";
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }
  async getEmail(): Promise<string> {
    try {
      this.common.loader = true;
      const res: any = await this.http
        .get("api/Customer/getMinimalProfileData", null, false, true)
        .toPromise();
      if (res?.Status) {
        this.customerEmail = res?.data?.personalInfo?.email?.value || "";
        return this.customerEmail;
      }
      return "";
    } catch (error) {
      console.log(error);
      return "";
    }
  }
  async getCustomerName(): Promise<string> {
    try {
      this.common.loader = true;
      const res: any = await this.http
        .get("api/Customer/getMinimalProfileData", null, false, true)
        .toPromise();
      if (res?.Status) {
        this.customerName = res?.data?.personalInfo?.name?.value || "";
        return this.customerName;
      }
      return "";
    } catch (error) {
      console.log(error);
      return "";
    }
  }
  subData() {
    let data = {
      CustomerId: "2004745",
      companyId: "UNITYCRM0021",
      userID: "1",
    };
    return this.http
      .get("api/Customer/getMinimalProfileData", data, false, true)
      .toPromise();
  }
  showAlerts(data) {
    if (this.isShowAlert) {
      return;
    }
    if (data.alerts.length == 0) {
      return;
    }
    this.isShowAlert = true;
    let timerInterval;
    let html = "";
    for (let index = 0; index < data.alerts.length; index++) {
      const alert = data.alerts[index];
      html =
        html + `<div class="text-left">${alert.message}</div><div><hr></div>`;
    }
    Swal.fire({
      title: "Alerts!",
      html: html,
      timer: 5000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector("strong").textContent =
            Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    });
  }

  getCustomerSubscription(customerid: any, single?: boolean) {
    try {
      this.common.loader = true;
      let data: any = {
        companyId: "UNITYCRM0021",
        userID: "1",
        CustomerId: customerid, // optional
      };

      if (single) {
        data.single = "yes";
      }
      return this.http
        .get("api/Customer/Subscriptions", data, false, true)
        .toPromise();
    } catch (err) {
      this.common.loader = false;
      throw Error(err);
    }
  }
  getCustomerPaymentProfile(data: any) {
    try {
      this.common.loader = true;
      let params: any = {
        profileId: data?.profileId,
        paymentProfileId: data?.paymentProfileId,
      };

      return this.http
        .get("api/subscription/getCustomerPaymentProfile", params, false, true)
        .toPromise();
    } catch (err) {
      this.common.loader = false;
      throw Error(err);
    }
  }

  getCustomerPersonalCardData() {
    try {
      return this.http
        .get("api/Customer/getCustomerPersonalInfo", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getLineInfoCardData() {
    try {
      let CustomerId = this.getCustomerId();
      return this.http.get("api/Customer/LineInfo/" + CustomerId).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
  getLineInfoSimType() {
    try {
      let data = {
        CustomerId: this.getCustomerId(),
      };
      // let CustomerId = this.getCustomerId()
      return this.http
        .get("api/Customer/GetSimType", data, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getLineInfoMoreCardData() {
    try {
      let CustomerId = this.getCustomerId();
      return this.http
        .get("api/Customer/GetlineInfoMore/" + CustomerId)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getCustomerInfoCardData() {
    try {
      return this.http
        .get("api/Customer/getCustomerInfo", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getCustomerUsagesCardData() {
    try {
      return this.http
        .get("api/Customer/getCustomerUsages", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  generatePaymentLink(customerId: number) {
    try {
      const params = new HttpParams()
        .set("companyId", "UNITYCRM0021")
        .set("CustomerId", this.http.getCustomerId().toString())
        .set("userID", this.http.getUser().userID.toString());
      const companyName = this.authService.getCompanyName().toUpperCase();
      const BUYFLOW_HOST = this.authService.getBuyFlowHost(companyName);
      return BUYFLOW_HOST
        ? this.httpClient.get<any>(
            `${BUYFLOW_HOST}/generate-link/${customerId}`,
            { params, responseType: "text" as "json" }
          )
        : (this.common.showToast(
            "error",
            "Error",
            "Unable to get BUYFLOW_HOST"
          ),
          null);
    } catch (error) {
      this.common.showToast("error", "Error", "Unable to call api request");
      throw Error(error);
    }
  }

  getSideMenuTypes() {
    try {
      // let url = "api/system/resource-permission-detail"
      let url = "api/system/resourcePermissionswithChilds";

      let body = {
        userID: this.getUser()?.userID,
        companyId: "UNITYCRM0021",
        "resource_Category[]": [
          "MENUS",
          // "SIDEBAR_MENU"
        ],
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getFinancialCardData() {
    try {
      return this.http
        .get("api/Customer/getFinancial-info", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getEligibilityCardData() {
    try {
      return this.http
        .get("api/Customer/getEligibilityInfo", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
  //method for future requirement
  getSubscriptionCardData() {
    try {
      return this.http
        .get("api/Customer/getMinimalProfileData", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getActivityInfo(data) {
    try {
      let body = {
        type: "CustomerEvents",
        ...data,
      };
      return this.http
        .get("api/Customer/getActivityInfo", body, false, true)
        ?.toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  updateCustomerProfile(data) {
    try {
      return this.http.put("api/Customer/changeCustomerInfo", data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
  sendToEmail(data) {
    try {
      // return this.http.post('api/Customer/UspsAddresses', data).toPromise()
      return this.http.post("api/Pre-paid/GetUserName", data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
  updateUspsAddresses(data) {
    try {
      // return this.http.post('api/Customer/UspsAddresses', data).toPromise()
      return this.http.post("api/Customer/ChangeAddress", data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  updateMDN(data: any) {
    try {
      return this.http.post("api/Customer/MdnChange", data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  updateSim(data) {
    try {
      return this.http.post("api/Customer/SimChange", data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
  generateQrCode(data) {
    try {
      return this.http.post("api/Customer/generateQRCode", data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  updateImei(data) {
    try {
      return this.http
        .post("api/Customer/CustomerDeviceUpdate", data)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  simChangeNonElec(data) {
    try {
      let body = {
        companyId: "UNITYCRM0021",
        userID: this.getUser()?.userID,
        ...data,
      };
      return this.http.post("api/Customer/SimChangeNonElec", body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  setCustomerId(id) {
    this.localStorage.store(this.customerIdKey, id);
    sessionStorage.setItem(this.customerIdKey, id);
    setTimeout(() => {
      this.CustomerDataUpdated.next(true);
    }, 1000);
  }

  getCustomerId() {
    return sessionStorage.getItem("CustomerId") ?? this.CustomerId;
  }

  getStatusOptions() {
    try {
      return this.http
        .get("api/Customer/GetCustomerStatus", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getExceptionCodeOptions() {
    try {
      return this.http
        .get("api/Customer/getExceptionCodes", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getDeEnrollCodeOptions() {
    try {
      return this.http
        .get("api/Customer/GetNladDeEnrollCodes", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getRejectionsOptions() {
    try {
      return this.http
        .get("api/Customer/GetRejections", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  updateStatus(data, typeCall) {
    let url = "api/Customer/CustomerStatus";
    if (typeCall === "non-electronic") {
      url = "api/Customer/ChangeStatusSystem";
    }
    try {
      return this.http.post(url, data).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getPlanScheduler() {
    try {
      let url = "api/downgrade-jobs/getPlanScheduler";
      let body = {
        companyId: "UNITYCRM0021",
        userID: this.getUser()?.userID,
        CustomerId: this.getCustomerId(),
        //"status": "Failed" // its optional
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  dashboardGetActivity() {
    try {
      let url = "api/Customer/getActivityInfo";
      let body = {
        reportType: "User",
        companyId: "UNITYCRM0021",
        userID: this.getUser()?.userID,
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getTicketStatsByAgent() {
    try {
      let url = "api/analytics/getTicketStatsByAgent";
      let body = {
        companyId: "UNITYCRM0021",
        LastDays: 15,
        userID: this.getUser()?.userID,
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getCarouselUpdates() {
    try {
      let url = "api/get-carousel-updates";
      let body = {
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
  getCustomerOtherInfo() {
    try {
      return this.http
        .get("api/Customer/getCustomerOtherInfo", null, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getNVUpdates(EnrollNumber) {
    try {
      let url = "api/acptransfer/getNVResponse";
      let body = {
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
        EnrollNumber: EnrollNumber,
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  createExceptionWorksheet(data) {
    try {
      var transferException;
      var jsonObject = JSON.parse(data.Request);
      if (jsonObject.transferException)
        transferException = jsonObject.transferException;
      else transferException = jsonObject.transException;
      let url = "api/acptransfer/exceptionWorksheet";
      let body = {
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
        EnrollNumber: data.EnrollNumber,
        ExceptionCode: transferException,
        Date: data.ConsentDate,
      };
      return this.http.post(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  downloadEnrollmentPdf() {
    try {
      let url = `api/acptransfer/enrollment-pdf/${this.getCustomerId()}`;
      let body = {
        companyId: "UNITYCRM0021",
        userID: this.http.getUser()?.userID,
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getUser() {
    return this.userDetails;
  }

  getShipperStationData() {
    try {
      let data = {};
      let url = "api/shipping/getStores";
      return this.http.get("api/shipping/getStores", data);
    } catch (err) {
      throw Error(err);
    }
  }


  async getCustomerDashboardResouceDetails(){
    try {
      let data={
        resource_Category:"CUSTOMER_DASHBOARD"
      }
      return this.http.get('api/system/resource-permission-detail',data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
  async getDeactivatedResouceDetails(){
    try {
      let data={
        resource_Category:"CUSTOMER_DASHBOARD"
      }
      return this.http.get('api/system/resource-permission-detail',data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
  async sideMenuInitilize() {
    try {
      const res = await this.getCustomerDashboardResouceDetails() as any;
      const data = res?.data;
      if (Array.isArray(data) && data.length > 0) {
        const resourceTypes = data[0]?.resource_types;
        if (Array.isArray(resourceTypes) && resourceTypes.length > 0) {
          this.executivePermission = resourceTypes[0]?.resources || [];
          const mappedMenuItems = this.mapedMenuItems();
          this.sideMenubarSub.next(mappedMenuItems);
        } else {
          console.error("Resource types array is empty or not defined");
        }
      } else {
        console.log("Data array is empty or not defined");
      }
    } catch (error) {
      console.error("Error occurred while fetching menu types:", error);
    }
  }
  

mapedMenuItems() {
  return this.executivePermission.map(el => {
    let tempData = {}
    let lastIndex = el.resource_permission.length - 1;
    let isHidden = el.resource_permission[0]?.CanView || 0;
    if (el.children.length == 0) {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),
        // isHidden: convertToBoolean(this.getPermission(el)),

        isHidden,
        // isHidden: convertToBoolean(el.HideMenu),

        icon: el.NavIconClass,
        type: "link",
        badgeType: "light-success",
        path: el.UrlRoute
      }


    } else {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),

        isHidden,

        icon: el.NavIconClass,
        type: "sub",
        badgeType: "light-success",
        path: el.UrlRoute,
        badgeValue: el.children?.length,
        children: el.children.map(el2 => {
          return {
            path: el2.UrlRoute, title: el2.DisplayName, type: "link"
          }
        })
      }
    }

    return tempData
  })
}
async deactiveInitilize() {
  try {
    const res = await this.getDeactivatedResouceDetails() as any;
    const data = res?.data;
    if (Array.isArray(data) && data.length > 0) {
      const resourceTypes = data[0]?.resource_types;
      if (Array.isArray(resourceTypes) && resourceTypes.length > 0) {
        this.executivePermission2 = resourceTypes[2]?.resources || [];
        const mappedMenuItems = this.DeactivedItems();
        this.sideMenubarSub2.next(mappedMenuItems);
      } else {
        console.error("Resource types array is empty or not defined");
      }
    } else {
      console.log("Data array is empty or not defined");
    }
  } catch (error) {
    console.error("Error occurred while fetching menu types:", error);
  }
}


DeactivedItems() {
return this.executivePermission2.map(el => {
  let tempData = {}
  let isHidden = el.resource_permission[0]?.CanView || 0;
  if (el.children.length == 0) {
    tempData = {
      title: el.DisplayName,
      isHidden,
      icon: el.NavIconClass,
      type: "link",
      badgeType: "light-success",
      path: el.UrlRoute
    }


  } else {
    tempData = {
      title: el.DisplayName,
      isHidden,
      icon: el.NavIconClass,
      type: "sub",
      badgeType: "light-success",
      path: el.UrlRoute,
      badgeValue: el.children?.length,
      children: el.children.map(el2 => {
        return {
          path: el2.UrlRoute, title: el2.DisplayName, type: "link"
        }
      })
    }
  }

  return tempData
})
}
getSideMenuBar() {
  return this.sideMenubarSub.asObservable();
}
getSideMenuBar2() {
  return this.sideMenubarSub2.asObservable();
}
}
