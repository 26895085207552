import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupUserService } from '../../services/group-user.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorage } from 'ngx-webstorage';


declare var bootstrap: any; 

@Component({
  selector: 'app-group-user',
  templateUrl: './group-user.component.html',
  styleUrls: ['./group-user.component.scss']
})
export class GroupUserComponent implements OnInit {


  @ViewChild('popoverElement') popoverElement!: ElementRef<HTMLDivElement>;
  @Input() groupUserDetails:any
  public addGroupForm:FormGroup;
  public check=true;
  moreInfo: boolean = false;
  popup1Width;
  popup2Width;
  checkDuplicateUser: any;
  groupTypeList: any;
  editId: any;
  groupowner: any;

  //pagination variable
  groupList: any;
  page:number=4;
  public itemsPerPage:number=10;
  public pageDropdownMenu:any=[10,20,30]
  public totalItems:any=25;
  currentPage:any=1;
  totalPage:any=1;

  

 


  @LocalStorage()
  private userDetails;

  constructor(private fb:FormBuilder,private groupUserService:GroupUserService,private commonService:CommonService,
    private _snackBar:MatSnackBar,
    private router:Router){
    this.addGroupForm=this.fb.group({
      groupName:["",[Validators.required,]],
      groupType:["",Validators.required],
      groupEmail:["",[Validators.required,Validators.email]],
      groupDescription:[""],
      groupOwner:["",Validators.required],
      status:["",Validators.required],
      newUserEmail:[]
    })
  }
  showPopover() {
 
    const element = this.popoverElement.nativeElement as HTMLElement;
    bootstrap.Popover.getInstance(element).show();
  }
  initPopovers() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });
  }
  
  hidePopover() {
   
    const element = this.popoverElement.nativeElement as HTMLElement;
    bootstrap.Popover.getInstance(element).hide();
  }
  

 

  ngOnInit(): void {
    this.getUser()
    this.getGroupType()
    this.getGroupList()
    this.getGroupOwnerList()
  }

 




  toggleMoreInf() {
    this.moreInfo = !this.moreInfo
    if(this.moreInfo){
    this.popup1Width = '83%';
    }else{
      this.popup1Width=''
    }
  }

  moreInfoTab:string = ''

  moreInfoSelectedTab(tabName:string) {
    this.moreInfoTab = tabName
  }

  moreInfoTab1:string = ''
  moreInfo1: boolean = false;


toggleMoreInf1()
{
  this.addGroupForm.reset();
  this.moreInfo1= !this.moreInfo1
  if(this.moreInfo1){
  this.popup2Width = '83%';
  this.popup1Width='92%'
  }else{
    this.popup2Width=''
    this.popup1Width='83%'
  }

}

moreInfoSelectedTab1(tabName:string){
  this.moreInfoTab1 = tabName
}


getGroupType(){
  this.groupUserService.getGroupType().subscribe((res:any)=>{
    this.groupTypeList=res.data
  })
}

getGroupList(search?){
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = startIndex + this.itemsPerPage;
 
  this.groupUserService.getGroupList(search).subscribe((res:any)=>{
    this.groupList=res.data
    this.groupList = res.data.slice(startIndex, endIndex);  
  },(err) =>{
    this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
  })
}
pageChange(event) {
  this.page = event
  this.currentPage=event
  this.getGroupList()
}

onChangeNumberOfPage(value: number | string) {
  this.itemsPerPage = Number(value); 
  this.currentPage = 1; 
  this.getGroupList();
}


filterdData(event:any){
  let data=event.target.value
  this.getGroupList(data);
}

getGroupOwnerList(){
  this.groupUserService.getGroupOwnerList().subscribe((res:any)=>{
    this.groupowner=res?.data?.data;
  },(err)=>{
    this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
  })
}


openSnackBar(message: string, duration: number = 2000) {
  this._snackBar.open(message, undefined, {
    duration: duration, verticalPosition: 'top'

  });
}

createGroup(){
  if(this.addGroupForm.invalid){
    this.addGroupForm.markAllAsTouched();
    return 
  }else{
    let ownerData=this.addGroupForm.value.groupOwner
    console.log(ownerData,"data<<<<<<<<<>>>>>>>>>>>")
    let code = this.commonService.replaceStringSpaceWithUnderScore(this.addGroupForm.value.groupName)

    let obj={
    companyId: "UNITYCRM0021",
    userID: this.getUser().userID,
    groupName:this.addGroupForm.value.groupName,
    GroupEmail:this.addGroupForm.value.groupEmail,
    GroupOwner:ownerData,
    Code:code,
    Description:this.addGroupForm.value.groupDescription,
    IsActive:this.addGroupForm.value.status,
    GroupTypeID: 1,
  }

  if(!this.editId){
  this.groupUserService.createGroup(obj).subscribe((res:any)=>{
    this.openSnackBar('User Group Created Successfully..',3000)
    this.moreInfo1= !this.moreInfo1
    this.popup2Width=''
    this.popup1Width='83%'
    this.getGroupList()
  })

}else{
  console.log(obj,"objjjjjjjjnnnnnnnnnnnn")
  this.groupUserService.updateGroupList(this.editId,obj).subscribe((res:any)=>{
    this.openSnackBar('UserGroup Updated Successfully..',3000)
    this.moreInfo1= !this.moreInfo1
    this.popup2Width=''
    this.popup1Width='83%'
    console.log(res,"updated Data>>>>>>>>>>>>")
  })
}
}
}

editGroup(id?){
  this.editId=id;
  let setValue:any=[]
  this.groupList.filter((item)=>{ 
  if(item.id==this.editId){
     setValue.push(item)
  }
  })

   console.log(setValue,"setValue")
    this.addGroupForm.patchValue({
    groupName:setValue[0].Name,
    groupType:setValue[0].group_type.Code,
    groupEmail:setValue[0].GroupEmail,
    groupDescription:setValue[0].Description,
    groupOwner:setValue[0].owner.id,
    status:setValue[0].owner.status_id,
  })
}

getUser() {
  // console.log(JSON.parse(localStorage.getItem('userDetails')),"oppppppppppp")
  return this.userDetails;
}

navigateForMember(id:any){
  this.router.navigate(["/add-member-group"], {
    queryParams: { id: id}
  })
}

navigateForPermission(id:any){
  this.router.navigate(["/manage-group-permissions"], {
    queryParams: { id: id}
  })
}


}
