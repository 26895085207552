<div class="row">
    <div class="col-12">
        <ngb-accordion #accT="ngbAccordion" [activeIds]="'toggle-1'">
            <ngb-panel id="toggle-1" title="Advance Search">
                <ng-template ngbPanelContent>
                    <form [formGroup]="advanceSearchOrderForm">
                        <div class="row">
                            <div class="col-6 col-md-3 my-2">
                              <label>Enrollment ID</label>
                              <input type="text" formControlName="enrollmentId" placeholder="Enrollment ID" class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>Customer ID</label>
                              <input type="number" formControlName="CustomerId" placeholder="Customer ID" class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>NLAD Application ID</label>
                              <input type="number" formControlName="nvApplicationId" placeholder="NLAD Application ID"
                                class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>NV Subscriber ID</label>
                              <input type="number" formControlName="nladSubscriberId" placeholder="NLAD Subscriber ID"
                                class="form-control" />
                            </div>
                            
                            <div class="col-6 col-md-3 my-2">
                              <label>First Name</label>
                              <input type="text" formControlName="firstName" placeholder="First Name" class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>Last Name</label>
                              <input type="text" formControlName="lastName" placeholder="Last Name" class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>Address</label>
                              <input type="text" formControlName="address" placeholder="Address" class="form-control" />
                            </div>

                            <div class="col-6 col-md-3 my-2">
                              <label>Alternate Contact number</label>
                              <input type="text" formControlName="alternateContactNumber" placeholder="Alternate Contact number" class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>Full Name</label>
                              <input type="text" formControlName="fullName" placeholder="Full Name" class="form-control" />
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>Country</label>
                              <select class="form-control" (change)="onSelectCountry()" formControlName="country">
                                <option value="">Select Country</option>
                                <option *ngFor="let country of countryOptions" [value]="country.id">{{country.CountryName}}
                                </option>
                              </select>
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>States</label>
                              <ng-select [items]="stateOptions" formControlName="states" bindValue="StateCode" bindLabel="StateName"
                                [multiple]="true" placeholder="Select"> </ng-select>
                             
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>Zip Code</label>
                              <input type="text" maxlength="5" formControlName="zipCode" placeholder="Zip Code"
                                class="form-control" />
                            </div>

                            <div class="col-4 my-2">
                              <label>Order Type</label>
                              <select class="form-control"  formControlName="OrderTypeID">
                                <option value="">Select Order Type</option>
                                <option *ngFor="let order of orderType" [value]="order.id">{{order.Description}}
                                </option>
                              </select>
                            </div>

                            <!-- <div class="col-4 my-2">
                              <label>Account Type</label>
                              <select class="form-control" formControlName="PlanTypeID">
                                <option value="">Select Account Type</option>
                                <option *ngFor="let account of accountType" [value]="account.id">{{account.Description}}
                                </option>
                              </select>
                            </div> -->

                           <!-- <div class="col-4 my-2">
                              <label>Account Status</label>
                              <select class="form-control" formControlName="CustomerStatusID">
                                <option value="">Select Status</option>
                                <option *ngFor="let status of customerStatus" [value]="status.id">{{status.Name}}
                                </option>
                              </select>
                            </div> -->

                            <div class="col-3 my-2">
                              <label>From</label>
                              <input type="date" formControlName="fromDate" placeholder="From" class="form-control">
                            </div>
                            <div class="col-6 col-md-3 my-2">
                              <label>To</label>
                              <input type="date" formControlName="toDate" placeholder="To" class="form-control">
                            </div>
                            <div class="col-12 my-2">
                              <div>
                                <div class="mt-2 d-flex align-items-center form-check-inline checkbox-dark mb-0">
                                  <ng-container *ngFor="let item of cards">
                                    <div class="d-flex align-items-center">
                                      <input type="checkbox" (change)="onClickStatus(item.Name)">
                                      <label class="form-check-label mx-2  mb-0">{{item.DisplayName}}</label>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex my-2" style="float: right;">
                                    <div class="input-control ms-2 d-flex align-items-end">
                                        <app-feather-icons (click)="resetAdvanceSearchOrder()" [icon]="'x-circle'"
                                            class="cursor-pointer"></app-feather-icons>
                                        <app-feather-icons (click)="onSubmitAdvanceSearchOrder()" [icon]="'search'"
                                            class="ms-2 cursor-pointer"></app-feather-icons>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>