import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
// import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { TableCellComponent } from './components/table-cell/table-cell.component';
// import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
// import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { ShowOptionsDirective } from "./directives/show-options.directive";
// Services
import { ChatService } from "./services/chat.service";
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { TableService } from "./services/table.service";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from './components/header/elements/swiper/swiper.component';
import { TokenInterceptor } from "./services/token.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';

import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';

import { CommonBreadcrumbComponent } from "./components/common-breadcrumb/common-breadcrumb.component";
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ToastComponent } from './components/toast/toast.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AdvanceSearchComponent } from './components/advance-search/advance-search.component';
import { ErrorValidatorsComponent } from './components/error-validators/error-validators.component';
import { AddEditTicketComponent } from "./components/add-edit-ticket/add-edit-ticket.component";
import { AdvanceSearchOrderComponent } from "./components/advance-search-order/advance-search-order.component";
import { ManageCommentsComponent } from "./components/manage-comments/manage-comments.component";
import { BulkDialogComponent } from './components/bulk-dialog/bulk-dialog.component';
import { ApplicationComponent } from './components/header/elements/application/application.component';
import { ChangeCompanyComponent } from "./components/header/elements/change company/change_company.component";
import { NgApexchartsModule } from 'ng-apexcharts';
import { GlobalLoaderComponent } from "./components/global-loader/global-loader.component";
import { CommonFilterComponent } from "./components/common-filter/common-filter.component";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NativeDateAdapter} from '@angular/material/core';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CommonBreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    GlobalLoaderComponent,
    TapToTopComponent,
    MegaMenuComponent,
    LanguagesComponent,
    ChangeCompanyComponent,
    NotificationComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    SvgIconComponent,
    SwiperComponent,
    TableCellComponent,
    SearchComponent,
    ToastComponent,
    AdvanceSearchComponent,
    AdvanceSearchOrderComponent,
    ErrorValidatorsComponent,
    AddEditTicketComponent,
    ManageCommentsComponent,
    BulkDialogComponent,
    ApplicationComponent,
    CommonFilterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbPaginationModule,
    TranslateModule.forRoot(),
    MessagesModule,
    MatSnackBarModule,
    CarouselModule, MatCheckboxModule, ToastModule,
    SweetAlert2Module,
    SwiperModule, MatStepperModule, MatFormFieldModule, MatButtonModule, MatButtonModule, MatInputModule, NgSelectModule,
    NgxDropzoneModule,
    NgApexchartsModule,
    MatDatepickerModule,
    BsDatepickerModule
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService,
    TableService,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    NativeDateAdapter
  ],
  exports: [

    NgbModule, FormsModule, ReactiveFormsModule, MatButtonModule,
    TranslateModule, NgbPaginationModule,
    LoaderComponent,GlobalLoaderComponent, BreadcrumbComponent, CommonBreadcrumbComponent,
    MatSnackBarModule,
    FeatherIconsComponent, TapToTopComponent,
    DisableKeyPressDirective, OnlyAlphabetsDirective,
    OnlyNumbersDirective, NgbdSortableHeader,
    TableCellComponent,
    SearchComponent,
    MatStepperModule,
    SweetAlert2Module,
    MatFormFieldModule, MatCheckboxModule, ToastModule, MessagesModule,
    SvgIconComponent, SwiperModule, MatButtonModule, MatInputModule, NgSelectModule, ToastComponent, NgxDropzoneModule, AdvanceSearchComponent, AdvanceSearchOrderComponent,
    ErrorValidatorsComponent,
    AddEditTicketComponent,
    ManageCommentsComponent,
    CommonFilterComponent
  ],
})
export class SharedModule { }
