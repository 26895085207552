import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private excludedUrls: string[] = [
    '/getCustomerUsages',
    "/Subscriptions",
    "/GetlineInfoMore",
    "/get-ticket-statuses",
    "/getMinimalProfileData",
    "/GetPrePaidPlans",
    "/getActivityInfo",
    "/getCustomerPersonalInfo",
    "/getPlanScheduler",
    "/getCustomerInfo",
    "/getEligibilityInfo",
    "/changeCustomerInfo",
    "/usageDetails",
    "/get-note"


  ];

  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isExcluded = this.excludedUrls.some(url => req.url.includes(url));
    const hideLoader = req.headers.get('X-Hide-Loader') === 'true';
    if (!hideLoader && !this.loaderService.expiredTokenModalOpen) {
      // Only show the loader if the modal is not open
      this.loaderService.showLoader();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!hideLoader && !this.loaderService.expiredTokenModalOpen) {
          // Only hide the loader if the modal is not open
          this.loaderService.hideLoader();
        }
      })
    );
  }
}
