import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class GroupUserService {
  userId: string;
  companyId:string;

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) { }
  getUser() {
    return this.userDetails;
  }

  getGroupType(){
    let url="api/system/group-types"
    let body={
      companyId: "UNITYCRM0021"
    }
    return this.http.get(url,body)
  }

 createGroup(obj){  
  let url="api/system/create-group"
  return this.http.post(url,obj)
}
  
  getGroupList(search?){
    let body={
      companyId: "UNITYCRM0021",
      userId: this.getUser().userId,
      groupName:search ? search : ""
    }

    let url="api/system/groups-list"
    return this.http.get(url,body)
  }

  
  


  updateGroupList(id,obj){
  let url=`api/system/update-group/${id}`
  return this.http.put(url,obj)
  }

  getGroupOwnerList(){
    let body={
      companyId: "UNITYCRM0021",
    }
    let url="api/system/agents-list"
    return this.http.get(url,body)
  }

  addMember(obj:any){
    let body={
      companyId: "UNITYCRM0021",
      userID:3,
      ...obj
    }
    console.log(body,"objjjjjjjjjjjbodyyyyyyyyyyyyyyyjjjjjjjjjjjj")
    let url="api/system/create-user-group"
    return this.http.post(url,body)
  }

}
